export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdravo i18n!!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslov na engleskom"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjer korištenja Vue i18n next uz vue-i18n-next"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli na našu stranicu proizvoda"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastike"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotovi dijelovi"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvod Turner"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S jednim od naših Murtfeldt turnera, postižete visok nivo individualnosti i fleksibilnosti za dizajn postrojenja. Ne proizvodimo turnere u seriji, već ih razvijamo i dizajniramo u tesnoj koordinaciji s vama. Ovdje je portfelj proizvoda "])},
  "produkt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.murtfeldt.de/de/produkte/individuelle-produkte/produktwender"])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vijci"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nudimo širok spektar prilagođenih plastičnih transportnih vijaka – bilo da su to vijci za singulaciju, vijci za grupiranje, 1-na-2 vijaka, 2-u-1 vijaka, dozirajući i transportni vijci, okretni vijci ili vijci za grupiranje. Svi oni prenose robu stabilno, brzo i nježno. Ovdje je portfelj proizvoda "])},
  "produkt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/foerderschnecken"])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatni set"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naši formatni setovi sastoje se od transportnih vijaka, transportnih zvijezda i vodilica od plastike u našem bogatom portfelju. Transportni vijci precizno se prilagođavaju odgovarajućem obliku i proporciji boce, kako je opisano na odgovarajućoj stranici. Transportne zvijezde mogu se proizvesti kao cjelovite, dvodijelne ili, za vrlo velike sustave, segmentirane. Vodiči se proizvode u obliku i dimenzijama prilagođenim projektu, opcionalno s izmjenjivim kliznim trakama. Naša obrada plastike optimizirana je u svim proizvodnim procesima. Ovdje je portfelj proizvoda "])},
  "produkt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/formatsaetze"])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenzionica"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Međunarodni standard za sustave zatezanja lanaca i remena od vodećeg proizvođača 'dizajniran i proizveden u Njemačkoj od 1965. godine'. Naši sustavi zatezanja pouzdano održavaju napetost lanaca i remena, čime se produžuje njihov vijek trajanja. Jednom montiran, zatezač automatski zateže lanac ili remen, čineći ga prvim izborom za sustave zatezanja s akcijom sile opruge. Ovdje je portfelj proizvoda "])},
  "produkt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/spannsysteme-fuer-ketten-und-riemen"])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdje se javlja trenje, javlja se i trošenje. Posebno gdje lančevi trče po metalnim vodičima, redovito podmazivanje je potrebno kako bi se suprotstavilo trošenju i razvoju buke. Naši samo-mazivi vodiči za lance, vodiči za remene i klizni profili iz naše dokazane Murtfeldt plastike 'S' i 'S1000' nisu samo ekonomska alternativa metalnim rješenjima. Kao klizna staza za valjkaste lance, okrugle lance ili remene, kombiniraju optimalna svojstva vožnje s najvišom otpornošću na habanje i jamče tih, glatki rad. Ovo štiti lance i remene i pomaže u smanjenju intervala održavanja sustava i strojeva. Do portfelja "])},
  "produkt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/profile-fuehrungen-und-gleitschienen"])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poluproizvodi"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijal S grupa"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PE-UHMW, PE-HMW. Od 1950-ih, Materijal S se dokazao u raznim primjenama u pogonima i tehnologiji transporta tisuće puta. Materijal S proizvodi se na temelju čistog polietilena ultravisoke molekularne mase (PE-UHMW TG 1.2) i jasno premašuje zahtjeve DIN 16972. Originalni Materijal S pripada najuspješnijoj skupini materijala među industrijskim plastikama. Posebno je Originalni Materijal S zelena postala prepoznatljiva marka u sektoru plastike i koristi se tamo gdje je potrebna tehnički učinkovita plastika. Originalni Materijal S zelena sinonim je za dobre klizne osobine, visoku otpornost na habanje i dugi vijek trajanja. Ovdje je portfelj Materijala S "])},
  "poluprodukt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Werkstoff-S-Gruppe/"])},
  "poluprodukt-plastike-tabela-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehničke plastike"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PA6, PA66, PA12, POM-C, PET. Na primjer, kod modificiranog lijevanog poliamida, proizvedenog tehnikom taloženja, molekula se ugrađuje mineralno ulje tijekom polimerizacije. To čini materijal samo-mazivim i zadržava izvrsna svojstva tijekom cijelog vijeka trajanja. To značajno smanjuje vaše operativne i održavateljske troškove. Malo koji materijal u našem asortimanu ima bolje klizne osobine. Osim toga, Murlubric ima izvrsnu otpornost na habanje i također je odobren za kontakt s hranom (EU i FDA), za razliku od crnog Murlubric-a. Ovdje je portfelj naših tehničkih materijala "])},
  "poluprodukt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Technische-Kunststoffe/"])},
  "poluprodukt-plastike-tabela-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visokoučinkovite plastike"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PA46, PVDF, PTFE, PPS, PEEK. Gdje su potrebna iznimna otpornost na kemijska i vruća opterećenja pare, odgovarajući su Materijali Murflor. Radni raspon materijala Murflor kreće se od -200°C i može doseći do +260°C bez mehaničkog napora. Murflor, proizveden na temelju PTFE, također ima najniži koeficijent klizne trenja od svih termoplastika. Ovdje je portfelj visokoučinkovitih materijala "])},
  "poluprodukt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Hochleistungskunststoffe/"])},
  "poluprodukt-plastike-tabela-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium PE plastike"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PE-1000, PE-500. Premium plastike iz Murdotec-a nude više od standardiziranog PE-UHMW. Odlikuju se vrlo dobrim kemijskim svojstvima, vrlo visokom čvrstoćom udarca i malom težinom. Tražite li svestranu tehničku plastiku? Ovdje vam nudimo odgovarajuće rješenje. Do portfelja "])},
  "poluprodukt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-premium-pe-kunststoffe.html"])},
  "poluprodukt-plastike-tabela-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posebne plastike PE-UHMW"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroz blisku suradnju s kupcima i intenzivna istraživanja u našim laboratorijima, Murdotec je kontinuirano razvijao svoje PE plastike. To nam danas omogućava da individualno i specifično odgovaramo na zahtjeve kupaca. Bilo da je optimiziran za habanje, klizanje za POM ili metale, toplinski stabiliziran, antimikrobni, detektibilni metalom, antistatički ili električki vodljivi: nudimo svojstva koja vam trebaju za vaše primjene. Do portfelja "])},
  "poluprodukt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-spezialkunststoffe.html"])},
  "poluprodukt-plastike-tabela-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cirkulacijske plastike"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehnički PE izvrsno je pogodan za ponovnu uporabu čipsa i rasutih materijala zbog svoje molekulske strukture. Čistoća raznolikosti i visok stupanj mljevenja značajno utječu na kvalitetu. Stoga svoje materijale prikladne za ponovnu uporabu pohranjujemo u obliku raznolikosti i miješamo samo vrste iste kvalitete. Kao da to nije dovoljno: u našim linijama miješanja svakom se seriji dodaje precizno određena količina novog materijala. S Murdotec 1000 U dobivate obrađeni materijal napravljen od baznog materijala PE-UHMW najviše kvalitete."])},
  "poluprodukt-plastike-link-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-umlaufkunststoffe.html"])},
  "poluprodukt-plastike-tabela-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialen"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postoji mnogo različitih vrsta rasutih materijala. Šećer, sol i žitarice samo su dio toga, baš kao i pijesak, šljunak ili ugljen. Povezujući element između ovih različitih sirovina je skladištenje u silosima, a pitanje odgovarajućeg materijala za oblaganje silosa također povezuje ove rasute materijale. Ovdje za moguće primjene "])},
  "poluprodukt-plastike-link-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/dialen.html"])},
  "poluprodukt-plastike-tabela-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suhi materijali/Tkanine"])},
  "poluprodukt-plastike-opis-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naš asortiman proizvoda uključuje visokokvalitetne tkanine od ugljičnih vlakana, staklenih vlakana i aramidnih vlakana. Tkanine se razlikuju po vrstama tkanja, koje se biraju na temelju specifičnih primjena. Glavne vrste tkanja su: - Obično tkanje - Siko tkanje - Satensko tkanje. Drugi kriterij odabira je težina po jedinici površine u g/m2. Pružamo tkanine u rasponu od otprilike 25 g/m2 do 1.000 g/m2."])},
  "poluprodukt-plastike-tabela-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grame po kvadratnom metru"])},
  "poluprodukt-plastike-kolona-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tkanje"])},
  "poluprodukt-plastike-red-1-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugljična"])},
  "poluprodukt-plastike-red-1-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siko 2/2 Obično"])},
  "poluprodukt-plastike-red-2-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staklena vlakna"])},
  "poluprodukt-plastike-red-2-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["98, 200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siko 2/2 Obično"])},
  "poluprodukt-plastike-red-3-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obično"])},
  "poluprodukt-plastike-red-4-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepreg"])},
  "poluprodukt-plastike-opis-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kada je potrebna maksimalna performansa u sektoru kompozita, koriste se prepezi (pre-impregnirani). Dosljedno niska frakcija volumena vlakana i savršeno impregniranje vlakana omogućuju proizvodnju komponenti od visokih performansi od vlaknastih kompozita. Autoklavi se koriste za kontrolirano, bezporozno stvrdnjavanje komponente. Rado ćemo udovoljiti vašim individualnim zahtjevima i pravovremeno dostaviti željena vlakna i potrebni profil primjene. Pažnja: Zahtijeva hlađenje."])},
  "poluprodukt-plastike-tabela-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grame po kvadratnom metru"])},
  "poluprodukt-plastike-kolona-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tkanje"])},
  "poluprodukt-plastike-red-1-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugljična"])},
  "poluprodukt-plastike-red-1-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siko 2/2 Obično"])},
  "poluprodukt-plastike-red-2-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staklena vlakna"])},
  "poluprodukt-plastike-red-2-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siko 2/2 Obično"])},
  "poluprodukt-plastike-red-3-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obično"])},
  "poluprodukt-plastike-red-4-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibrid"])},
  "poluprodukt-plastike-red-4-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po potrebi"])},
  "poluprodukt-plastike-red-4-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po potrebi"])},
  "poluprodukt-plastike-red-5-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOA Prepreg"])},
  "poluprodukt-plastike-opis-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepezi izvan autoklava (OOA) pre-impregnirani (prepreg) materijali obećavaju alternativu tradicionalnoj obradi autoklavom (AC), s potencijalom smanjenja troškova obrade i izgradnje struktura bez veličinskih ograničenja nametnutih od strane AC. OOA prepezi razlikuju se od AC-obradenih prepeza po tome što su dizajnirani samo za obradu s vrećicom za vakuum i stvrdnjavanje u pećnici. Pažnja: Zahtijeva hlađenje."])},
  "poluprodukt-plastike-tabela-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grame po kvadratnom metru"])},
  "poluprodukt-plastike-kolona-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tkanje"])},
  "poluprodukt-plastike-red-1-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugljična"])},
  "poluprodukt-plastike-red-1-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 380, 600"])},
  "poluprodukt-plastike-red-1-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siko 2/2 Obično"])},
  "poluprodukt-plastike-red-2-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuumski materijali"])},
  "poluprodukt-plastike-opis-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lako oblikovan protok pomoći, nova i pametna kombinacija odvajajućeg filma, perforiranog filma i peel ply-a ili sprejnog ljepila s ravnomjernim sprejnim uzorkom osigurava brigu i sigurnu pripremu vakuumskih infuzija ili vrećastih vakuumskih postupaka. Različite trake za brtvljenje vakuma za različite temperaturne raspone ili s različitim ljepilima snage upotpunjuju ponudu."])},
  "poluprodukt-plastike-tabela-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odvajajući filmovi"])},
  "poluprodukt-plastike-red-1-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite debljine"])},
  "poluprodukt-plastike-red-1-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NP, P3, P33"])},
  "poluprodukt-plastike-red-2-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuumski filmovi"])},
  "poluprodukt-plastike-red-2-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite debljine"])},
  "poluprodukt-plastike-red-2-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150/300"])},
  "poluprodukt-plastike-red-3-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disajni/Tkanine"])},
  "poluprodukt-plastike-red-3-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite debljine"])},
  "poluprodukt-plastike-red-3-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuumski crijeva"])},
  "poluprodukt-plastike-red-4-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za autoklav/vakuumsku infuziju"])},
  "poluprodukt-plastike-red-4-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peel Ply"])},
  "poluprodukt-plastike-red-5-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smole/Očvršćivači"])},
  "poluprodukt-plastike-opis-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U usporedbi s UP smolama, epoksidne smole nude značajno poboljšana mehanička svojstva (statička i dinamička čvrstoća, modul elastičnosti) i kemijsku otpornost. Obrada epoksidnih smola također je puno jednostavnija, eliminirajući štetne pare stirena. Širok raspon viskoznosti za različite procese poput vakuumskih infuzija, RTM-a ili ručne izrade omogućuje fleksibilnost u proizvodnji. Svi očvršćivači su kompatibilni međusobno i mogu se miješati kako bi se individualno prilagodila svojstva."])},
  "poluprodukt-plastike-tabela-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S avio odobrenjem"])},
  "poluprodukt-plastike-red-1-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za vakuumsku infuziju"])},
  "poluprodukt-plastike-red-2-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za konstrukcije"])},
  "poluprodukt-plastike-red-3-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metali"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrada metala. U kategoriji metala nudimo različite opcije obrade (ugovorni rad) našim klijentima. Ponuđene usluge uključuju CNC tokarenje, CNC frezanje, 3D printanje, brušenje, poliranje, bušenje, savijanje, probijanje, obradu lima, zavarivanje, zavarene konstrukcije, lasersko graviranje, lasersko rezanje i izradu kalupa."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Tokarenje/Frezanje"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilni smo kad je u pitanju savladavanje novih izazova, ali apsolutno precizni u pogledu kvalitete i rokova isporuke. Naša proizvodnja ekskluzivno je opremljena najsuvremenijim visokotehnološkim strojevima i najnovijim mjerilima. Kroz blisku suradnju s našim klijentima postižemo optimalne procese. Kompetentni kontakti brzo odgovaraju na vaše upite. Imamo izvrsno iskustvo u obradi tehničkih plastika i radujemo se vašim upitima."])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavarivanje/Zavarene Konstrukcije"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za proizvodnju zavarenih konstrukcija koristimo različite procese (MIG, MAG, TIG). Koristimo materijale poput čelika, nehrđajućeg čelika ili aluminija prema vašim željama. Proizvodnja se temelji na crtežnim predlošcima, bilo da su dizajnirani unutar tvrtke ili dostavljeni od strane vas."])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Namještaj & Dizajn"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovoj kategoriji promoviramo naše lokalne talente i pružamo platformu da se izraze. S velikom kreativnošću stvaraju se individualna remek-djela od prirodnih kompozitnih materijala/tehnologije. Svaki komad je jedinstven. Provjerite sami."])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dućan Kompozitnih Materijala"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije proizvodnje namještaja po narudžbi"])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovom odjeljku nudimo dodatne usluge poput organizacije projekata, tehničkog savjetovanja, montažne usluge za sklopove, konstrukcije, inženjeringa unatrag, izrade prototipa, razvoja prototipa i proizvodnje."])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacija Projekata"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U organizaciji projekata pratimo vaše projekte u provedbi, kontroli i osiguranju kvalitete prema dogovorima/smjernicama koje tražite i prema međunarodnim standardima. Radujemo se svakom novom izazovu."])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montažna Usluga za Sklopove"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U montažnoj usluzi za sklopove, prefabrikirane komponente sastavljaju se u funkcionalan krajnji proizvod (sklop). To uključuje montažu mehaničkih, elektroničkih, pneumatskih i/ili hidrauličnih sustava. Prilikom sastavljanja pojedinih komponenti, naše iskusne montažne ekipe sastavljaju ih u sklop komponente ili djelomični sklop prema nacrtu. Montaža se odvija na montažnoj liniji prilagođenoj vašim komponentama, svaki pojedini korak montaže obavlja se stručno i ekonomično. Nakon svake montaže, vrše se temeljite kontrole kvalitete, a odobrenje za otpremu dodjeljuje se nakon toga."])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstrukcija"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od ideje do završetka strojeva, potrebno je mnogo koraka, idealno unutar opsega odgovornosti jednog proizvođača. Specijalizirani smo za izradu različitih komponenti u strojarstvu i industriji obrade metala, kao i u izradi kalupa. Od automatiziranih sustava do tehnologije do obrade lima – brinemo se za sve potrebne korake za vas i surađujemo s vama. Osim naše osnovne kompetencije u izradi specijalnih strojeva i konstrukciji na temelju najnovijih CAD programa (SolidWorks i AutoDesk Inventor), nudimo inovativna rješenja za vaše potrebe i ideje. Tijekom cijelog projekta, mi smo vaš stalni pratitelj i radujemo se zajedničkoj razmjeni ideja."])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverzni inženjering"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U području reverznog inženjeringa izlizanih komponenti, nepostojećih CAD podataka, kao i nepostojećih tehničkih crteža, imamo godine iskustva u rekonstrukciji i reprodukciji oštećenih ili izlizanih komponenata. Radujemo se vašim upitima ili projektima."])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Namještaj & Dizajn"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovoj kategoriji promoviramo naše lokalne talente i pružamo im platformu da se izraze. Individualna remek-djela stvaraju se s velikom kreativnošću koristeći prirodne kompozitne materijale i tehnologiju. Cimmer Design predstavlja i prikazuje svoje radove."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dućan Kompozitnih Materijala"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije izrade namještaja po narudžbi"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Nama"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma F.E.S-BH D.O.O ima sjedište u Bosni i Hercegovini s dva lokala, te jednim u Turskoj. Mi smo sistemski dobavljač u gotovo svim sektorima industrije, uključujući izradu mašina i postrojenja, transportnu tehniku, automobilsku industriju, obnovljive izvore energije, medicinsku tehnologiju, kao i zrakoplovstvo i svemir. Pravovremeno smo prepoznali probleme u Evropi i prihvatili izazove kako bismo podržali evropski prostor s našim postojećim tehničkim mogućnostima i iskustvom. Povećanjem vaših doprinosa i smanjenjem sada veoma visokih troškova, osiguravate svoja radna mjesta i ostajete konkurentni. Osvijestite se o ekonomskim prednostima i iskoristite naše znanje, kao i veliku mrežu partnerskih odnosa u prodaji i proizvodnji. Radujemo se svakom novom izazovu i projektu. Ne oklijevajte, kontaktirajte nas."])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
}