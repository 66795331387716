<template>


  <section id="vueper-carousel">
    <v-card flat class="fullpage ">
      
      <!--vueper-slides bullets-outside fixed-height="500px" style="width: 100%;" :dragging-distance="50" lazy lazy-load-on-drag-->
        <vueper-slides class=" mx-20"  
          bullets-outside style="width: 100%;" 
          :dragging-distance="50" 
          lazy 
          lazy-load-on-drag   
          autoplay
          :pause-on-hover="pauseOnHover"
          @autoplay-pause="internalAutoPlaying = false"
          @autoplay-resume="internalAutoPlaying = true"
          fractions progress
          fade 
          >

            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
              :title="slide.title"
              :content="slide.content"
              :video="slide.video" />
              <template v-slot:loader>
                <span>Loading...</span>
              </template>
        
        </vueper-slides>

    </v-card>
  </section>

 <section id="plastike">
    <v-card class="fullpage white1">
      
          <!--h1>Full page Website </h1--> 
          <plastike :inicijalniJezik="en"/>
          
    </v-card>
  </section>

  <section id ="metali">
     <v-card class="fullpage white1 pt-8">

      <metali :inicijalniJezik="en"/>
     
    </v-card>
  </section>

  <!--section id ="cimmer">
     <v-card class="fullpage white1  pt-8">

      <cimmer :inicijalniJezik="en"/>
     
    </v-card>
  </section-->


  <section id ="usluge">
     <v-card class="fullpage white1 pt-8">

      <usluge :inicijalniJezik="en"/>
     
    </v-card>
  </section>

  <section id ="footer">
    <footerbar :inicijalniJezik="en"/>
  </section>

  <div class="sections-menu">
        <span 
          class="menu-point" 
          v-bind:class="{active: activeSection == index}" 
          v-on:click="scrollToSection(index)" 
          v-for="(offset, index) in offsets" 
          v-bind:key="index" 
          v-title="'Go to section ' + (index+1)">
  
        </span>
  </div>

</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import axios from 'axios';
import plastike from '@/components/PlastikeForm.vue'
import metali from '@/components/MetaliForm.vue'
//import cimmer from '@/components/CimmerForm.vue'
import usluge from '@/components/UslugeForm.vue'
import Footer from '@/components/FooterForm.vue'


export default {
  components: {
    'footerbar':Footer,
    VueperSlides, VueperSlide,
    plastike,
    metali,
    //cimmer,
    usluge

  },
  data() {
    return {

      slides: [ //embed generator https://www.classynemesis.com/projects/ytembed//
      {           
        title: 'a',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Logo_siri.jpg' 
      },

      {           
        title: '',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Slide_2.jpg' 
      },
      
      {           
        title: '',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Slide_3.jpg' 
      },  
      /* cimmer slide
      {           
        title: '',
        content: '',
        image:'https://fes-bh.ba/slider-photos/Slide_4.jpg' 
      },*/            
  
      /*{
            video: {
        
              
              mp4: `video/Elvir_FES.mp4`,
              props: { autoplay: true, loop: true, controls: false, muted: true }
            }
          },

      {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              /*url: 'https://www.youtube.com/embed/ehJg_OlcjpE?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',*/
  /*            url: 'https://www.youtube.com/embed/lQyyZSJ2LJw??rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;'
              }
            }
          },

          
          {
            title: '',
            content: '',
            image: 'https://www.protiq.com//out/marketplace/merchants/b3d444a46e728f34323ba39c0a836cca/logo/thumbs/420x420e/murtfeld.png',
            video: {
              url: 'https://www.youtube.com/embed/ob-3C8unXtQ?rel=0&showinfo=0&controls=0&fs=0&modestbranding=1&color=white&iv_load_policy=3&autohide=1&enablejsapi=1',
              props: {
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              },
              pointerEvents: false //This video can\'t be interacted with.
            }
          }*/
        ],      
      items: [],
      inMove: false,
      inMoveDelay: 400,
      activeSection: 0,
      offsets: [],
      touchStartY: 0



    };
  },
  mounted() {
    /* procedure za full page
    this.getDataKarticePrviRed();  */ 

    //this.calculateSectionOffsets();
    /*
    window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);  // Mozilla Firefox
    window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
   */
    //window.addEventListener('touchstart', this.touchStart, { passive: false }); // mobile devices
    //window.addEventListener('touchmove', this.touchMove, { passive: false }); // mobile devices
  
  },
  /**
   * destroyed() hook executes on page destroy and removes all registered event listeners
   */
   unmounted() {
    /* procedure za full page 
    window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
    window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false });  // Other browsers
     */
    //window.removeEventListener('touchstart', this.touchStart); // mobile devices
    //window.removeEventListener('touchmove', this.touchMove); // mobile devices
  

    
  },

  methods: {
    getDataKarticePrviRed() {
      const cArgumenti={
        cBrojReda:1,
        cOrder: 'Order by kolonauredu ASC'

      };

      axios
        .get('public/php_sloj/frontpage_crud.php', { params: cArgumenti }) // Koristimo get_data.php fajl koji vraća SELECT upit
        .then((response) => {
          this.items = response.data;
          console.log(this.items);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    calculateSectionOffsets() {
      //let sections = document.getElementsByClassName('section');
      
      let sections = document.getElementsByTagName('section');
      let length = sections.length;
      console.log('broj sekcija: ' + length);


      for(let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
        //console.log('sekcije ' + i);
      }
    },

    handleMouseWheel: function(e) {
      
      if (e.wheelDelta < 30 && !this.inMove) {
        this.moveUp();
      } else if (e.wheelDelta > 30 && !this.inMove) {
        console.log('mouse down je pritisnut')
        this.moveDown();
      }
        
      e.preventDefault();
      return false;
    },


    
    /**
     * Handle the 'DOMMouseScroll' event for Firefox
     */
     handleMouseWheelDOM: function(e) {
      
      if (e.detail > 0 && !this.inMove) {
        this.moveUp();
      } else if (e.detail < 0 && !this.inMove) {
        this.moveDown();
      }
      
      return false;
    },
    /**
     * Move to the previous section or the last section if you're on the first section
     */
    moveDown() {
      this.inMove = true;
      this.activeSection--;
        
      if(this.activeSection < 0) this.activeSection = this.offsets.length - 1;
        
      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Move to the next section or the first section if you're on the last section
     */
    moveUp() {
      this.inMove = true;
      this.activeSection++;
        
      if(this.activeSection > this.offsets.length - 1) this.activeSection = 0;
        
      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Scrolls to the passed section id if the section exists and the delay is over
     */
    scrollToSection(id, force = false) {
      if(this.inMove && !force) return false;
      
      this.activeSection = id;
      this.inMove = true;
      
      // get section and scroll into view if it exists
      let section = document.getElementsByTagName('section')[id];
      //let section =document.getElementsByClassName('section')[id];
      if(section) {
        document.getElementsByTagName('section')[id].scrollIntoView({behavior: 'smooth'});
        //document.getElementsByClassName('section')[id].scrollIntoView({behavior: 'smooth'});
      }
      
      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);
      
    },
    /**
     * Handles the 'touchstart' event on mobile devices
     */
    touchStart(e) {
      e.preventDefault();
      
      this.touchStartY = e.touches[0].clientY;
    },
    /**
     * Handles the 'touchmove' event on mobile devices
     */
    touchMove(e) {
      if(this.inMove) return false;
      e.preventDefault();
      
      const currentY = e.touches[0].clientY;
      
      if(this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }
      
      this.touchStartY = 0;
      return false;
    }
  


  },
}
</script>


<style>

body {
  font-family: 'Segoe UI', Arial, Helvetica, sans-serif;	
  /*font-family: Helvetica, arial, sans-serif;*/
  margin: 0;
  color: #FFF; 
  overflow: hidden;
}


.dugmad {
    color:rgb(119, 126, 120) !important; /*primary (je za plavo)*/
    background-color: #f4f2f2 !important;

}
.naslov_kartica{
	/*font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
  min-width: 100%;*/
  /*background: linear-gradient(110deg, #344955 60%, #4A6572 60%);*/
  /*background: linear-gradient(110deg,   #626060 50%, #9c9a9a);*/
  background: linear-gradient(110deg,   #cecccc 50%, #e0dddd);
	color: #3d3c3c;
	font-size: 25px!important;
	font-style: normal!important;
	font-variant: normal!important;
	font-weight: bold!important;
	font-stretch: normal!important;
	line-height: 1 !important;
	text-transform: uppercase !important;
  border: 1px solid rgb(197, 196, 196);
}
.tekst_kartica{
  /*font-style: italic;*/
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  /*color: #222222 !important;*/
  color: #6E6E6E !important;
/**/
}
.tekst_kartica_kontakt{
  /*font-style: italic;*/
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  /*color: #222222 !important;*/
  color: #6E6E6E !important;
/**/
}
.malitekst_kartica{
  font-size: 10px;
	font-weight: bold;
	text-align: center;
	color:#6E6E6E;
}

.kontejner-kontakt{
  
}

.pozadinakolona_lijevo_kartica_kontakti {
  font-family: 'Segoe UI', Arial, Helvetica, sans-serif;	
  background-color: #EDEDED;
  border: 1px solid rgb(197, 196, 196);
  padding: 20px; /* Prilagodite vrijednost paddinga za veći razmak */
  margin:0.2px; /* Dodajte razmak između kolona */
  
  
}

.pozadinakolona_lijevo_kartica {
  font-family: 'Segoe UI', Arial, Helvetica, sans-serif;	
  background-color: #EDEDED;
  border: 1px solid rgb(197, 196, 196);
  padding: 20px; /* Prilagodite vrijednost paddinga za veći razmak */
  margin:0.2px; /* Dodajte razmak između kolona */

  height:438px;
  overflow-y: scroll;
}
.pozadinakolona_desno_kartica {
  background-color: #ffffff;
  border: 1px solid rgb(197, 196, 196);
  padding: 20px; /* Prilagodite vrijednost paddinga za veći razmak */
  margin:0.5px;

  height:438px;
  
}
.pozadina_kartica{
  background-color: #EDEDED;
}
.container{
  background-color: #EDEDED;
  border: 1px solid rgb(197, 196, 196); /* Postavljanje granice */
  padding: 0px; /* Dodavanje razmaka unutar granice */
}
.transparentna_traka{
	font-weight: bold !important;
    font-size: 15px !important;
    color:#ffffff;
    /*background-color: #b2b2b2;*/
    background-color: rgba(10, 10, 10, 0.6);
    height: 90px; 
}
p {
  margin-bottom: 15px;
  color: #FFF;
}



.fullpage {
  /*height: 100vh;*/
  width: 100% !important; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}










/*.fullpage a, .fullpage span {
  text-decoration: none;
  font-weight: 600;
  background: rgba(93, 189, 10, 0.2);
  padding: 5px 10px;
  color: #FFF;
  margin-left: 5px;
}*/

.red {
  background-color: #ab4545;
}

/*section.black {
  background-color: #000;
}*/
.white1 {
  background-color: #ffffff!important;

}
.frontpodnaslov_white1{
	font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
  min-width: 100% !important;
  text-align: center !important;
  
  /*background: linear-gradient(110deg, #344955 60%, #4A6572 60%);*/
  /*background: linear-gradient(to right, rgb(255, 255, 255),#000000 60%, rgb(255, 255, 255));-*/
  background: linear-gradient(to right, rgb(255, 255, 255),#5c5b5b 60%, rgb(255, 255, 255));
  /*border: 1px solid rgb(197, 196, 196);  Postavljanje granice */

}

.podnaslov_white1{
  /*font-style: italic;*/
  font-weight: bold !important;
  font-size: 1em;

  text-align: left;
  color:#000000 !important;

}

.tekst_white1{
  /*font-style: italic !important;*/
  font-weight: bold !important;
  font-size: 20px !important;

  text-align: left !important;
  color: #626161 !important;

}

.h1_plavi {
  font-size: 4em;
  margin: 0;
  text-align: center;
  padding: 0 1rem;  
  color: #085AAE !important;

}

.podnaslov_plavatraka {
  font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
  min-width: 100%;
  /*background: linear-gradient(110deg, #344955 60%, #4A6572 60%);*/
  background: linear-gradient(to right, rgb(255, 255, 255),#085AAE 60%, rgb(255, 255, 255));
}

.tekst_plavi{
  /*font-style: italic !important;*/
  font-weight: bold !important;
  font-size: 20px !important;

  text-align: left !important;
  color: #085AAE !important;

}

h1.white1 {
  font-size: 4em;
  margin: 0;
  text-align: center;
  padding: 0 1rem;  
  color: #0e0d0d !important;
}
h2.white1 {
  font-size: 2em;
  margin: 0;
  text-align: center;
  padding: 0 8rem;  
  color: hsl(150, 2%, 17%) !important;
  background-color: #10a549 !important;
}
p.white1 {
  font-style: italic;
  font-size: 1.5em;
  padding: 0 8rem; 
  text-align: center;
  color: #2b2c2c !important;
}

.plavi {
  background-color: #E7F0F6!important;

}

.blue {
  background-color: #237ad4;
}

.green {
  background-color: #5E9F6D !important;
}

.purple {
  background-color: #42325B !important;
}
h1.purple {
  color: #10a549;
}
.black {
  background-color: #000000 !important;
}
h1.black {
  color: #c2bcbc !important;
}

.sections-menu {
  position: fixed;
  right: 1rem;

  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.sections-menu .menu-point {
  width: 10px;
  height: 10px;
  background-color: #FFF !important;
  color:#FFF !important;
  display: block;
  margin: 1rem 0;
  opacity: .6;
  transition: .4s ease-in-out all;
  cursor: pointer;
  z-index: 0;
}

.sections-menu .menu-point.active {
  opacity: 1;
  transform: scale(1.5);
}

.sections-menu .menu-point:hover {
  opacity: 1;
  transform: scale(1.2);
}



/* divajderi */
.nosac_dividera{
  display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: auto;
      min-height: 3px; /* Minimalna visina za .container */
      padding: 35px 30px 15px 20px; /* Opcioni padding za razmak oko .gradient_divajder */

    
}

.gradient_divider {
  width: 100%;
  height: 3px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgba(13, 8, 96, 1) 0%,
    rgba(9, 9, 121, 1) 21%,
    rgba(6, 84, 170, 1) 51%,
    rgba(0, 255, 113, 1) 100%
  ); 
}


/*   divider sa tekstom u sredini start */
/* https://codepen.io/benknight/pen/zxxeay */
/* upotreba: 

<h2 class="divider line one-line" contenteditable>Simple 1px</h2>

*/
.divider {
    display: flex;
}

.divider:before,
.divider:after {
    content: "";
    flex: 1;
}
.divider_slova_naslov_plavi{
  font-weight: bold !important;
  font-size: 4em !important;
  margin: 0;
  text-align: center;
  padding: 0 1rem;  
  color: #085AAE !important;

}

.divider_slova_naslov_tamnosivi{
  font-weight: bold !important;
  font-size: 4em !important;
  margin: 0;
  text-align: center;
  padding: 0 1rem;  
  color: #626161 !important;

}

.divider_slova_podnaslov{
	font-weight: bold !important;
  font-size: 20px !important;
  color: rgb(94, 92, 92) !important;

}
.line {
  display: flex;
  align-items: center;
  margin: 1em -1em;
}

.line:before,
.line:after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 400px;
  margin: 0 0.5em;
}

.one-line-tamnosiva:before,
.one-line-tamnosiva:after {
  background: #626161;
}

.one-line-plava:before,
.one-line-plava:after {
  background:#085AAE;
}
/*   divider sa tekstom u sredini kraj */


.vueperslide{
    text-align: left !important;
    
}
.vueperslide__title {
  font-size: 3em;
  opacity: 0.7;
  color:#ffffff;
  text-align: right !important;
  top:50%;
  left:5%;
  position:absolute;
}

.vueperslide__content {
  font-size: 2em;
  /*opacity: 0.7;*/
  color:#fff;
  top:85%;
  left: 5%;;
  /*right: 0;*/


  position:absolute;
}

.sheet_format{


}

@media screen and (max-width: 1200px) {
  h1 {
    font-size: 2.5em;
  }
}

/* Prilagodite stil za ekrane manje od 768 piksela širine (mobilni uređaji) */
@media (max-width: 768px) {
    .sheet_format {
      /* Dodajte željene promene za male ekrane ovde */
      width: 480px !important; 
    }

    .divider_slova_naslov_tamnosivi{
      font-weight: bold !important;
      font-size: 2em !important;
      margin: 0;
      text-align: center;
      padding: 0 1rem;  
      color: #626161 !important;
    }

    .pozadinakolona_lijevo_kartica {
      font-family: 'Segoe UI', Arial, Helvetica, sans-serif;	
      background-color: #EDEDED;
      border: 1px solid rgb(197, 196, 196);
      padding: 50px; /* Prilagodite vrijednost paddinga za veći razmak */
      margin:0.5px; /* Dodajte razmak između kolona */
      width: 250px;
      height:438px;
      width: auto;
      overflow-y: scroll;
    }
    .pozadinakolona_desno_kartica {
      background-color: #ffffff;
      border: 1px solid rgb(197, 196, 196);
      padding: 50px; /* Prilagodite vrijednost paddinga za veći razmak */
      margin:0.5px;
      width: auto;
      height:438px;

    }




}

/* Prilagodite stil za ekrane između 769 i 1024 piksela širine (tableti) */
@media (min-width: 769px) and (max-width: 1024px) {
  .sheet_format {
    /* Dodajte željene promene za tablete ovde */
    width: 769px !important; 
  }

  .divider_slova_naslov_tamnosivi{
    font-weight: bold !important;
    font-size: 2em !important;
    margin: 0;
    text-align: center;
    padding: 0 1rem;  
    color: #626161 !important;

  }


  .kontejner-kontakt{
    width: 80% !important;

  }
}

/* Prilagodite stil za ekrane veće od 1024 piksela širine (desktop računari) */
@media (min-width: 1025px) {
  .sheet_format {
    /* Dodajte željene promene za desktop računare ovde */
    width: 1025px !important; 
  }

  .divider_slova_naslov_tamnosivi{
    font-weight: bold !important;
    font-size: 4em !important;
    margin: 0;
    text-align: center;
    padding: 0 1rem;  
    color: #626161 !important;

  }
}



.tabelaukartici {
    width: 100%;
    border-collapse: collapse;
  }

.tabelaukartici th, .tabelaukartici td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .tabelaukartici td {
    font-size: 12px;
  }

  .tabelaukartici th {
    background-color: #919191; /* Siva pozadina za nazive kolona */
    color: white;
    font-size: 12px;
  }

  .kontejner-kontakt{
    width: 800px !important;

  }
  
  .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
  }
  .vueperslides__progress {
  background: rgba(123, 130, 138, 0.25);
  color: #0768a9;
}
</style>
