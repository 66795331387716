<template>
    <v-app>
      <v-main>
        <v-header> ... </v-header>
        <v-navigation-drawer> ... </v-navigation-drawer>
        <router-view/>
      </v-main>
      <v-footer> ... </v-footer>
    </v-app>
  </template>
  <script>
</script>