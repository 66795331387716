
/* eslint-disable no-unused-vars */
import Vue from 'vue';
//import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/HomePage.vue';
//import Home from './components/HomeForm.vue';
import Home1 from './components/HomeForm1.vue';
import Products from './components/ProductsForm.vue';
import AboutUs from './components/AboutUsForm.vue';
import Contact from './components/ContactForm.vue';
import LoginForm from './components/dashboard/LoginForm.vue';
import GalerijaUrnek from './components/GalerijaUrnek.vue';

import UpdateForm from './UpdateTexta.vue';

//Vue.use(VueRouter);
/*
const routes = [
  { path: '/', component: Home },
  { path: '/products', component: Products },
  { path: '/about', component: AboutUs },
  { path: '/contact', component: Contact },
];
*/
/*const router = new VueRouter({
  routes,
  mode: 'history', // This removes the '#' from the URLs
});*/


const router = createRouter({
  // Configuration for your router
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/home1', component: Home1 },
    { path: '/products', component: Products },
    { path: '/about', component: AboutUs },
    { path: '/contact', component: Contact },
    { path: '/login', component: LoginForm },
    { path: '/update', component: UpdateForm },
    { path: '/galerijaurnek', component: GalerijaUrnek }
  ],
});

export default router;
