<template>

<row id="mapa">
  <v-card elevation="0" class="fullpage white1" min-width="100%">
    
    <div id="map" class=" " style="width: 100%; height: 400px"></div>

  </v-card>
</row>

<row id="kontakt" >

  <v-card elevation="0" class="fullpage white1 pt-6" min-width="100%">


    <v-row>
      <v-col class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
        {{ $t("title-kontakt") }}
      </v-col>
    </v-row>

    <v-row justify="center" class=" pt-4 pb-8" >


          <v-container  class=" container kontejner-kontakt" >

            <v-row >
              <v-col cols="12" sm="12" >
                <v-card elevation="0"  min-width="100%">
                          <v-card-text class="naslov_kartica text-center">
                            FES System supplier
                          </v-card-text>
                      </v-card>
              </v-col>
            </v-row>
              <v-row class="justify-between px-3"  >

                  <v-col  cols="12" sm="12"  >

                      <div class="pozadinakolona_lijevo_kartica_kontakti mx-2">                   
                        <div class="tekst_kartica_kontakt" >
                          75414, Teocak<br />
                          Husejnovici b.b<br />
                          info@fes-bh.ba <br />
                          Tel: +387 60343 7208<br /><br />

                          F.E.S-BH d.o.o<br />

                          Bulevar mira bb<br />
                          76100 Brčko<br />
                          Bosnia and Hercegowina<br />
                          info@fes-bh.ba<br />
                          Tel: +387 62 249 892<br /><br />

                          F.E.S-Global<br />

                          Harmantepe Sokak 1<br />
                          52300 Ünye/Ordu<br />
                          Turkey<br />
                          global@fes.com<br />
                          Tel: +90 544 912 5904

                          
                        </div>   
                      </div>                                     
                                                      

                  </v-col>


              </v-row>
              <v-row justify="center ">
                <v-col  cols="12" sm="5">
                  <!--v-btn
                            class="ma-2 dugmad"                                                                                               
                            variant="outlined"
                            @click="toggle"
                          >
                        
                            
                            <v-icon>
                                mdi-link
                            </v-icon>
                        </v-btn-->    
                </v-col>
              </v-row>                  
          </v-container>


    </v-row>


  </v-card>

</row>



<row id ="footer" class=" pt-4 " >

    <footerbar :inicijalniJezik="en"/>

</row>

</template>

<script>
import Footer from '@/components/FooterForm.vue'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
export default {

  components: {
    'footerbar':Footer
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {

      //fes map 44.60356698487255, 19.0034881964427
      const map = L.map('map').setView([44.60356698487255, 19.0034881964427], 50); // Centar i nivo zumiranja
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
      
      // Dodavanje markera
      const marker = L.marker([44.60356698487255, 19.0034881964427]).addTo(map);
      marker.bindPopup('FES SYSTEM SUPPLIER').openPopup();
    }
  }
};



</script>
<style>



</style>