<template>

<v-card 
      elevation="0"
      class="mx-auto my-12"
      max-width="90%"
      color="transparent">


      <v-card color="transparent"  flat class="py-5" max-width="100%"> 
        <v-row justify="center">
          <h1>Welcome to Our Product site</h1>
          <h1>{{ $t("productsiteheader") }}</h1>

        </v-row>
      </v-card>  

</v-card>


</template>

<script>


export default {
  components: {
    
  },
};
</script>