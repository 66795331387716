<template>
  
  <v-app class="bckg_kartica_main">

    <!--topnavbar></topnavbar-->
    <!--sidebar></sidebar-->
    
    <!--sidebarAI></sidebarAI-->
    
    <v-navigation-drawer

      expand-on-hover
      rail
      app  
      permanent 
      clipped 
        

      >
        <v-list>
            <!--v-list-item
              prepend-avatar="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg"
              title="Logo"
              subtitle="firma_email@gmailcom"
            ></v-list-item-->
            <v-list-item>              
              <v-img   
                :width="300"
                aspect-ratio="16/9"
                cover 
                src="./images/Logo.jpg"></v-img>
            </v-list-item>          
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-home"  value="home" to="/"> {{ $t("menu-home") }}</v-list-item>
            <!--v-list-item prepend-icon="mdi-shopping" title="Produkti" value="produkti" to="/products"></v-list-item-->
            <v-list-item prepend-icon="mdi-camera-iris" value="onama" to="/about"> {{ $t("menu-onama") }}</v-list-item>
            <v-list-item prepend-icon="mdi-google-maps"  value="kontakt" to="/contact"> {{ $t("menu-kontakt") }}</v-list-item>
            <!--v-list-item prepend-icon="mdi-camera-iris" value="galerija" to="/galerijaurnek"> Galerija urnek</v-list-item-->
            <v-list-item prepend-icon="mdi-earth" title="" value="" to="">
              <localeswitcher :inicijalniJezik="en"/>
            </v-list-item>
            

        </v-list>
        
        
      </v-navigation-drawer>


    <v-main  >
      <!--v-header> header </v-header-->

      <!--v-navigation-drawer permanent  expand-on-hover--> 
       
      <!--/v-navigation-drawer-->
      <!-- Dodajte ikonu koja će otvarati/zatvarati navigation drawer -->
   
      <!--v-container class="bckg_kartica_main"-->
        
        <router-view/>
      <!--/v-container-->
    
    </v-main>
    
    
    <!--v-footer> 
      <footerbar></footerbar>  
    </v-footer-->
    
    <!--footerbar></footerbar-->  

  </v-app>

</template>


<script>
//import SidebarAI from './components/SidebarAI.vue'
//import Sidenavbar from './components/SideNavigacija.vue'
//import Topnavbar from './components/TopBarNav.vue'
//import Footer from './components/FooterForm.vue'
/*import NavigationMenu from './components/NavigationMenu.vue';*/
import LocaleSwitcher from "./components/LocaleSwitcher.vue";

export default {
  name: 'App',
  components: {
    //'footerbar':Footer,
    'localeswitcher':LocaleSwitcher
    
    //'topnavbar' : Topnavbar,
    //'sidebar' : Sidenavbar,
    //'sidebarAI' : SidebarAI
  },
};
</script>

<style>
.bckg_kartica_main {

  background-image: -webkit-linear-gradient(top, rgba(89,89,89,0.65) 0%, rgba(89,89,89,0) 100%) !important;
  background-image: linear-gradient(to bottom, rgba(89,89,89,0.65) 0%, rgba(89,89,89,0) 100%) !important;
}
</style>
