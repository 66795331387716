//import { createApp } from 'vue'
//import App from './App.vue'
//createApp(App).mount('#app')

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//import { createVuetify } from 'vuetify';
import { createVuetify } from 'vuetify'; // Promijenjeno
// Import Vuetify styles
import 'vuetify/dist/vuetify.min.css';

// Import all Vuetify components
import * as VuetifyComponents from 'vuetify/components';

import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'

//style.scss
import "vue-awesome-sidebar/src/scss/vue-awesome-sidebar.scss";

import i18n from './i18n'



const app = createApp(App).use(i18n);




// Use Vuetify components globally
Object.keys(VuetifyComponents).forEach((key) => {
    app.component(key, VuetifyComponents[key]);
  });


// Create a Vuetify instance
//const vuetify = createVuetify();

/*const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3f51b5', // Prilagodite ovo na željenu primarnu boju
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark: {
        primary: '#3f51b5', // Prilagodite ovo na željenu primarnu boju za tamni način rada
      },
    },
  },
});*/
// Create a Vuetify instance
// Create a Vuetify instance with theme options
const vuetify = createVuetify({
  theme: {
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark: {
        primary: '#3f51b5',
      },
    },
  },
});


app.use(router); // Use the Vue 3 version of Vue Router
app.use(vuetify); // Use Vuetify as a plugin
app.use(vueAwesomeSidebar);




app.mount('#app');






