
<template>
    <v-card
      flat
      tile
      class="footersite  text-center"
      
        >
        <v-card-text>
        <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
        >
            <v-icon size="24px">
            {{ icon }}
            </v-icon>
        </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          {{  $t("footer-tekst-1") }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Kliker</strong>
        </v-card-text>
    </v-card>    
</template>
<script>

export default {
  
  data(){
    return {
     icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      location: 'end',
    }
  },
};

</script>
<style>


.footersite{
	/*font-weight: bold !important;
  font-size: 20px !important;*/
  color: #818080 !important;
  /*background: linear-gradient(110deg, #0c0c0cbd 60%, rgb(137, 139, 138));*/
  background-color: rgb(62, 61, 61) !important; 
  /*background: linear-gradient(to top, #344955 0%, #344955 100%);*/
}
</style>
