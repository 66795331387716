export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title on english"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Our Product site"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastics"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished Parts"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Turner"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With one of our Murtfeldt turners, you achieve a high level of individuality and flexibility for the design of the production plant. We do not produce turners in series, but develop and design them in close coordination with you. Here is the product portfolio "])},
  "produkt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.murtfeldt.de/de/produkte/individuelle-produkte/produktwender"])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screws"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a broad range of custom plastic conveyance screws – whether singulating screws, grouping screws, 1-in-2 strand screws, 2-in-1 strand screws, dosing and conveying screws, turning screws, or turning grouping screws. They all transport goods steadily, quickly, and gently. Here is the product portfolio "])},
  "produkt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/foerderschnecken"])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format Set"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our format sets consist of conveyance screws, conveyor stars, and guide components made from plastics in our extensive portfolio. Conveyance screws are precisely matched to the respective bottle shape and proportion, as described on the corresponding page. The conveyor stars can be manufactured as complete, two-piece, or, for very large systems, as segmented. Guide components are produced in a project-specifically coordinated form and dimension, optionally with exchangeable sliding strips. Our plastic processing is optimized in all manufacturing processes. Here is the product portfolio "])},
  "produkt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/formatsaetze"])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tensioning Box"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The international standard for chain and belt tensioning systems from the market leader 'designed & produced in Germany since 1965'. Our tensioning systems reliably keep chains and belts tensioned, thus promoting their lifespan. Once mounted, the tensioner automatically tensions the chain or belt, making it the first choice for tensioning systems with spring force action. Here is the product portfolio "])},
  "produkt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/spannsysteme-fuer-ketten-und-riemen"])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where friction occurs, wear occurs. Especially where chains run on metal guides, regular lubrication is necessary to counteract wear and noise development. Our self-lubricating chain guides, belt guides, and sliding profiles made from our proven Murtfeldt plastics 'S' and 'S1000' are not only a cost-effective alternative to metal solutions. As a slide rail for roller chains, round link chains, or belts, they combine optimal running properties with the highest wear resistance and guarantee quiet, smooth operation. This protects chains and belts and helps minimize maintenance intervals for systems and machinery. To the portfolio "])},
  "produkt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/profile-fuehrungen-und-gleitschienen"])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-Finished Products"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material S Group"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category of materials PE-UHMW, PE-HMW. Since the 1950s, Material S has proven itself in a wide range of applications in drive and conveyor technology thousands of times over. Material S is produced based on pure ultrahigh molecular weight polyethylene (PE-UHMW TG 1.2) and clearly exceeds the requirements of DIN 16972. Original Material S belongs to the most successful material group among industrial plastics. In particular, Original Material S green has established itself as a brand in the plastic sector and is used wherever technically efficient plastic is needed. Original Material S green is synonymous with good sliding properties, high wear resistance, and a long service life. Here is the Material S portfolio "])},
  "poluprodukt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Werkstoff-S-Gruppe/"])},
  "poluprodukt-plastike-tabela-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Plastics"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category of materials PA6, PA66, PA12, POM-C, PET. In the example of modified cast polyamide, produced based on PA6 C, a mineral oil is incorporated during polymerization. This makes the material self-lubricating and retains its excellent properties over the entire service life. This significantly reduces your operating and maintenance costs. Hardly any plastic in our range has better sliding properties. In addition, Murlubric has excellent wear resistance and is also approved for food contact (EU and FDA) in contrast to black Murlubric. Here is the portfolio of our technical materials "])},
  "poluprodukt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Technische-Kunststoffe/"])},
  "poluprodukt-plastike-tabela-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-Performance Plastics"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category of materials PA46, PVDF, PTFE, PPS, PEEK. Where outstanding resistance to chemical and hot steam loads is required, Murflor materials are suitable. The working range of Murflor materials starts at -200°C and can reach up to +260°C without mechanical stress. Murflor, produced based on PTFE, also has the lowest coefficient of sliding friction of all thermoplastics. Here is the portfolio of high-performance materials"])},
  "poluprodukt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Hochleistungskunststoffe/"])},
  "poluprodukt-plastike-tabela-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium PE Plastics"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category of materials PE-1000, PE-500. Premium plastics from Murdotec offer more than standardized PE-UHMW. They are characterized by very good chemical resistance, very high impact strength, and low weight. Are you looking for a versatile technical plastic? We offer you the suitable solution here. To the portfolio "])},
  "poluprodukt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-premium-pe-kunststoffe.html"])},
  "poluprodukt-plastike-tabela-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Plastics PE-UHMW"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through close collaboration with customers and intensive research in our laboratories, Murdotec has continuously developed its PE plastics. This enables us today to respond individually and specifically to customer requirements. Whether wear-optimized, glide-optimized for POM or metals, heat-stabilized, antimicrobial, metal detectable, antistatic, or electrically conductive: we offer the properties you need for your applications. To the portfolio "])},
  "poluprodukt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-spezialkunststoffe.html"])},
  "poluprodukt-plastike-tabela-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulation Plastics"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical PE is excellently suited for the reuse of chips and bulk goods due to its molecular structure. Variety purity and the high degree of grinding significantly influence the quality. Therefore, we store our materials suitable for reuse in variety-pure form and only mix types of the same quality. As if that weren't enough: in our mixing lines, a precisely determined amount of new material is added to each batch. With Murdotec 1000 U, you receive processed material made from the base material PE-UHMW of the highest quality."])},
  "poluprodukt-plastike-link-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-umlaufkunststoffe.html"])},
  "poluprodukt-plastike-tabela-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialen"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many different types of bulk goods. Sugar, salt, and grain are just as much a part of it as sand, gravel, or coal. The connecting element between these different raw materials is storage in silos, and the question of the suitable lining material for the silos also connects these bulk goods. Here for possible applications "])},
  "poluprodukt-plastike-link-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/dialen.html"])},
  "poluprodukt-plastike-tabela-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dry Fabrics/Fabrics"])},
  "poluprodukt-plastike-opis-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our product range includes high-quality fabrics made from carbon fibers, glass fibers, and aramid fibers. The fabrics differ in the types of weaves, which are selected based on specific applications. The main types of weaves are: - Plain weave - Twill weave - Satin weave. Another selection criterion is the weight per unit area in g/m2. We provide fabrics ranging from approximately 25 g/m2 to 1,000 g/m2."])},
  "poluprodukt-plastike-tabela-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grams/Square meter"])},
  "poluprodukt-plastike-kolona-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weave"])},
  "poluprodukt-plastike-red-1-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Plain"])},
  "poluprodukt-plastike-red-2-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass Fiber"])},
  "poluprodukt-plastike-red-2-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["98, 200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Plain"])},
  "poluprodukt-plastike-red-3-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain"])},
  "poluprodukt-plastike-red-4-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepreg"])},
  "poluprodukt-plastike-opis-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whenever maximum performance in the composite sector is required, prepregs (pre-impregnated) are used. A consistently low fiber volume fraction and perfect impregnation of the fiber enable the production of high-performance fiber composite components. Autoclaves are used for the controlled, pore-free curing of the component. We are happy to accommodate your individual requirements and deliver the desired fibers and required application profile promptly. Attention: Requires refrigeration."])},
  "poluprodukt-plastike-tabela-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grams/Square meter"])},
  "poluprodukt-plastike-kolona-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weave"])},
  "poluprodukt-plastike-red-1-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Plain"])},
  "poluprodukt-plastike-red-2-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass Fiber"])},
  "poluprodukt-plastike-red-2-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Plain"])},
  "poluprodukt-plastike-red-3-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain"])},
  "poluprodukt-plastike-red-4-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "poluprodukt-plastike-red-4-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As needed"])},
  "poluprodukt-plastike-red-4-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As needed"])},
  "poluprodukt-plastike-red-5-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOA Prepreg"])},
  "poluprodukt-plastike-opis-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-Autoclave (OOA) pre-impregnated (prepreg) materials are a promising alternative to traditional autoclave processing (AC), with the potential to reduce processing costs and build structures without size limitations imposed by AC. OOA prepregs differ from AC-processed prepregs in that they are designed only for processing with a vacuum bag and oven curing. Attention: Requires refrigeration."])},
  "poluprodukt-plastike-tabela-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grams/Square meter"])},
  "poluprodukt-plastike-kolona-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weave"])},
  "poluprodukt-plastike-red-1-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 380, 600"])},
  "poluprodukt-plastike-red-1-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Plain"])},
  "poluprodukt-plastike-red-2-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacuum Materials"])},
  "poluprodukt-plastike-opis-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easily drapable flow aid, the new and clever combination of release film, perforated film, and peel ply or spray adhesive with a uniform spray pattern ensures quick and secure preparation of vacuum infusions or vacuum bagging. Various vacuum sealing tapes for different temperature ranges or with different adhesive strengths complete the offer."])},
  "poluprodukt-plastike-tabela-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Films"])},
  "poluprodukt-plastike-red-1-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various thicknesses"])},
  "poluprodukt-plastike-red-1-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NP, P3, P33"])},
  "poluprodukt-plastike-red-2-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacuum Films"])},
  "poluprodukt-plastike-red-2-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various thicknesses"])},
  "poluprodukt-plastike-red-2-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150/300"])},
  "poluprodukt-plastike-red-3-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breather/Fabrics"])},
  "poluprodukt-plastike-red-3-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various thicknesses"])},
  "poluprodukt-plastike-red-3-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacuum Hoses"])},
  "poluprodukt-plastike-red-4-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For autoclave/vacuum infusion"])},
  "poluprodukt-plastike-red-4-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peel Ply"])},
  "poluprodukt-plastike-red-5-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resins/Hardeners"])},
  "poluprodukt-plastike-opis-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compared to UP resins, epoxy resins offer significantly improved mechanical properties (static and dynamic strength, modulus of elasticity) and chemical resistance. Processing epoxy resins is also much simpler, eliminating harmful styrene vapors. A wide range of viscosities for different processes such as vacuum infusion, RTM, or hand lay-up offers flexibility in manufacturing. All hardeners are compatible with each other and can be mixed to individually adjust the properties."])},
  "poluprodukt-plastike-tabela-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Aviation Approval"])},
  "poluprodukt-plastike-red-1-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Vacuum Infusion"])},
  "poluprodukt-plastike-red-2-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Structural Components"])},
  "poluprodukt-plastike-red-3-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metals"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metal Processing. In the Metals category, we offer various processing options (contract work) to our customers. The following services are offered as contract work. CNC Turning, CNC Milling, 3D Printing, Grinding, Polishing, Drilling, Bending, Punching, Sheet Metal Processing, Welding, Welding Constructions, Laser Engraving, Laser Cutting, and Mold Making."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Turning/Milling"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are flexible when it comes to mastering new challenges but absolutely precise in terms of quality and delivery dates. Our production is exclusively equipped with state-of-the-art high-tech machines and the latest measuring instruments. Through close collaboration with our customers, we achieve optimal processes. Competent contacts respond quickly to your inquiries. We have excellent experience in machining technical plastics and look forward to your inquiries."])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welding/Welding Constructions"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the production of welding constructions, we use various processes (MIG, MAG, TIG). We use materials such as steel, stainless steel, or aluminum according to your wishes. The production is based on drawing templates, either designed in-house or provided by you."])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Furniture & Design"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this category, we promote our local talents and offer a platform for them to express themselves. With great creativity, individual masterpieces are created from natural composites/materials/technology. Each piece is unique. Please see for yourself."])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials/Nature/Technology"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials SHOP"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description from the category of custom furniture production"])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, we offer additional services such as project organization, application technical consulting, assembly service for assemblies, construction, reverse engineering, prototyping, prototype development, and manufacturing."])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Organization"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In project organization, we accompany your projects in implementation, control, and quality assurance with the agreements/guidelines requested by you and according to international standards. We look forward to every new challenge."])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembly Service for Assemblies"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the assembly service for assemblies, prefabricated components are assembled into a functional end product (assembly). This includes the assembly of mechanical, electronic, pneumatic, and/or hydraulic systems. In assembling the individual components, our experienced assembly teams assemble them into a component assembly or a partial assembly according to the blueprint. Assembly takes place in an assembly line tailored to your components, each individual assembly step is carried out professionally and economically. After each assembly, thorough quality controls are performed, and approval for shipment is granted."])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the idea to the completion of machines, many steps are required, ideally within the scope of a single manufacturer's responsibilities. We specialize in the construction of various components in mechanical and plant engineering as well as mold construction. From automated systems to feed technology to sheet metal processing – we take care of all necessary steps for you and work closely with you. In addition to our core competence in special machine construction and construction based on the latest CAD programs (SolidWorks and AutoDesk Inventor), we offer innovative solutions for your needs and ideas. Throughout the project, we are your constant companion and look forward to a shared exchange of ideas."])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse Engineering"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the field of reverse engineering of worn components, non-existent CAD data, as well as non-existent technical drawings, we have years of experience reconstructing and reproducing damaged or worn components. We look forward to your inquiries or projects."])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Furniture & Design"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this category, we promote our local talents and provide a platform for them to express themselves. Individual masterpieces are created with great creativity using natural composite materials and technology. Cimmer Design presents and showcases its works."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials/Nature/Technology"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials Shop"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description from the category of individual furniture manufacturing"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company F.E.S-BH D.O.O is headquartered in Bosnia and Herzegovina with two locations, and one location in Turkey. We are a system supplier in almost all areas of industry, including machinery and plant construction, conveyor technology, automotive, renewable energy, medical technology, as well as aerospace. Recognizing the challenges in Europe in a timely manner, we have embraced the opportunity to support the European region with our existing technical capabilities and experience. By increasing your contribution margins and reducing the now very high costs, you secure your jobs and remain competitive. Convince yourself of the economic advantages and also benefit from our know-how and a large network of sales and production partners. We look forward to every new challenge and project. Do not hesitate, contact us."])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
}