export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merhaba i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce Başlık"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue i18n next örneği olan bir Vue i18n örneği"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Sitemize Hoş Geldiniz"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastikler"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitmiş Parçalar"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Döndürücü"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murtfeldt döndürücülerimizden biri ile yüksek bir düzeyde bireysellik ve üretim tesisinin tasarımı için esneklik elde edersiniz. Seri halinde döndürücüler üretmiyoruz, ancak size yakın koordinasyon içinde geliştirip tasarlıyoruz. İşte ürün portföyü "])},
  "produkt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.murtfeldt.de/de/produkte/individuelle-produkte/produktwender"])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidalar"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tek sıralı vidalar, grup vidaları, 1'e 2 sıralı vidalar, 2'ye 1 sıralı vidalar, dozaj ve taşıma vidaları, döndürme vidaları veya döndürme grup vidaları olsun, özel plastik taşıma vidalarımız geniş bir yelpazede sunulmaktadır. Hepsi malzemeleri istikrarlı, hızlı ve nazik bir şekilde taşır. İşte ürün portföyü "])},
  "produkt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/foerderschnecken"])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format Seti"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format setlerimiz, plastiklerden yapılmış taşıma vidaları, taşıma yıldızları ve rehber bileşenlerini içerir. Taşıma vidaları, ilgili şişe şekline ve oranına tam olarak uyacak şekilde tasarlanmıştır ve ilgili sayfadaki açıklamada belirtildiği gibi. Taşıma yıldızları tam, iki parçalı veya çok büyük sistemler için segmentli olarak üretilebilir. Rehber bileşenler, proje özel olarak koordine edilmiş bir form ve boyutta üretilir, isteğe bağlı olarak değiştirilebilir kayma şeritleri ile. Plastik işleme süreçlerinde tüm üretim süreçlerinde optimize edilmiştir. İşte ürün portföyü "])},
  "produkt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/formatsaetze"])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germe Kutusu"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piyasa liderinin Almanya'da 1965'ten beri 'tasarlanan ve üretilen' zincir ve kayış germe sistemleri için uluslararası standart. Germe sistemlerimiz zincirleri ve kayışları güvenli bir şekilde germekte, bu da ömürlerini teşvik etmektedir. Bir kez monte edildikten sonra gerici otomatik olarak zinciri veya kayışı gerer, bu da onu yay kuvvetli germe sistemleri için ilk tercih haline getirir. İşte ürün portföyü "])},
  "produkt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/spannsysteme-fuer-ketten-und-riemen"])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiller"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sürtünme meydana geldiğinde aşınma meydana gelir. Özellikle zincirlerin metal rehberlerde çalıştığı yerlerde, aşınma ve gürültü gelişimine karşı koymak için düzenli yağlama gerekir. Kendi kendine yağlamalı zincir rehberlerimiz, kayış rehberleri ve Murtfeldt plastiklerimizden yapılmış kayma profillerimiz 'S' ve 'S1000', metal çözümlere maliyet etkili bir alternatif sunar. Silindir zincirleri, yuvarlak halka zincirleri veya kayışlar için bir kayma rayı olarak, optimal çalışma özelliklerini en yüksek aşınma direnciyle birleştirir ve sistemlerin ve makinelerin bakım aralıklarını en aza indirgemeye yardımcı olur. Portföy "])},
  "produkt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/profile-fuehrungen-und-gleitschienen"])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarı Mamul Ürünler"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme S Grubu"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme PE-UHMW, PE-HMW kategorisi. 1950'lerden beri, Malzeme S, sürüş ve taşıma teknolojisinin birçok uygulamasında binlerce kez kendini kanıtlamıştır. Malzeme S, saf ultrayüksek moleküler ağırlıklı polietilen (PE-UHMW TG 1.2) temel alınarak üretilir ve DIN 16972'nin gereksinimlerini açıkça aşar. Orijinal Malzeme S, endüstriyel plastikler arasındaki en başarılı malzeme grubu arasında sayılır. İşte ürün portföyü "])},
  "poluprodukt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/halbzeuge/verbund-pa-pe-pom"])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme M Grubu"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme M, tıbbi cihaz teknolojisinde, telekomünikasyon, elektronik, optoelektronik, robot teknolojisi ve otomotiv endüstrisinde güvenle kullanılmaktad. Yüksek mukavemet ve sertlik, düşük ağırlık, iyi işlenebilirlik ve uzun ömür, Malzeme M'yi çok çeşitli uygulamalarda vazgeçilmez bir malzeme haline getirir. İşte ürün portföyü "])},
  "poluprodukt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/halbzeuge/pa6-gf30-uva"])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme M-U Grubu"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme M-U, dişli özellikler ve yüksek bir sürekli çalışma sıcaklığı of 120 ° C'ye kadar olan uygulamalarda kullanım için özel olarak geliştirilmiştir. Malzeme M-U'nun mükemmel aşınma direnci ve yüksek termal dayanıklılığı, dişli pens dişlileri ve zincir gergisi gibi hassas dişli uygulamalarda başarılı bir şekilde kullanılmasını sağlar. İşte ürün portföyü "])},
  "poluprodukt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/halbzeuge/pa6-gf30-uva"])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme T Grubu"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme T, özellikle aşınma direnci ve darbe dayanımı gerektiren uygulamalarda kullanım için uygundur. Malzeme T'nin özellikleri, benzersiz aşınma direnci, yüksek sürekli çalışma sıcaklığı ve mükemmel darbe dayanımını içerir. İşte ürün portföyü "])},
  "poluprodukt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/halbzeuge/pa6-gf30-uva"])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme S Grubu"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malzeme S, özellikle sürtünme ve aşınma direnci gerektiren uygulamalarda kullanım için uygundur. Malzeme S'nin özellikleri arasında mükemmel aşınma direnci, yüksek sürekli çalışma sıcaklığı ve düşük sürtünme katsayısı bulunur. İşte ürün portföyü "])},
  "poluprodukt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/halbzeuge/verbund-pa-pe-pom"])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolaşım Plastikleri"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teknik PE, moleküler yapısı nedeniyle yongaların ve toptan malzemelerin yeniden kullanımı için mükemmel bir şekilde uygundur. Çeşit saflığı ve yüksek derecede öğütme kaliteyi önemli ölçüde etkiler. Bu nedenle, yeniden kullanım için uygun malzemelerimizi çeşit saflığında depoluyoruz ve sadece aynı kalitedeki türleri karıştırıyoruz. Eğer bu yetmezmiş gibi: karışım hatlarımızda her partiye kesin olarak belirlenmiş miktarda yeni malzeme eklenir. Murdotec 1000 U ile en yüksek kaliteli PE-UHMW baz malzemeden üretilmiş işlenmiş malzeme elde edersiniz."])},
  "poluprodukt-plastike-link-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-umlaufkunststoffe.html"])},
  "poluprodukt-plastike-tabela-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialen"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birçok farklı toptan malzeme türü vardır. Şeker, tuz ve tahılın yanı sıra kum, çakıl veya kömür de bunun bir parçasıdır. Bu farklı hammadde türleri arasındaki bağlantı elemanı silolardaki depolama ve silolar için uygun kaplama malzemesi sorusunu da bağlar. İşte olası uygulamalar için burada "])},
  "poluprodukt-plastike-link-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/dialen.html"])},
  "poluprodukt-plastike-tabela-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuru Kumaşlar / Kumaşlar"])},
  "poluprodukt-plastike-opis-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün yelpazemiz karbon fiberler, cam fiberler ve aramid fiberlerden yapılmış yüksek kaliteli kumaşları içerir. Kumaşlar, belirli uygulamalara dayalı olarak seçilen örgü türlerinde farklılık gösterir. Başlıca örgü türleri şunlardır: - Düz örgü - Gabardin örgü - Saten örgü. Bir diğer seçim kriteri, g/m2 cinsinden birim alan başına ağırlıktır. Yaklaşık 25 g/m2 ila 1.000 g/m2 arasında değişen kumaşlar sağlıyoruz."])},
  "poluprodukt-plastike-tabela-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gram/Metrekare"])},
  "poluprodukt-plastike-kolona-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örgü"])},
  "poluprodukt-plastike-red-1-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karbon"])},
  "poluprodukt-plastike-red-1-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabardin 2/2 Düz"])},
  "poluprodukt-plastike-red-2-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cam Fiber"])},
  "poluprodukt-plastike-red-2-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["98, 200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabardin 2/2 Düz"])},
  "poluprodukt-plastike-red-3-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düz"])},
  "poluprodukt-plastike-red-4-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepreg"])},
  "poluprodukt-plastike-opis-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit sektöründe maksimum performans gerektiğinde, prepregler (önceden emprenye edilmiş) kullanılır. Sürekli düşük fiber hacim fraksiyonu ve fiberin mükemmel emprenye edilmesi, yüksek performanslı fiber kompozit bileşenlerin üretimini sağlar. Bileşenin kontrollü, gözeneksiz bir şekilde iyileşmesi için otoklavlar kullanılır. Bireysel gereksinimlerinizi karşılamaktan mutluluk duyarız ve istenen fiberleri ve gerekli uygulama profilini hızlı bir şekilde teslim ederiz. Dikkat: Soğutma gerektirir."])},
  "poluprodukt-plastike-tabela-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gram/Metrekare"])},
  "poluprodukt-plastike-kolona-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örgü"])},
  "poluprodukt-plastike-red-1-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karbon"])},
  "poluprodukt-plastike-red-1-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-1-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabardin 2/2 Düz"])},
  "poluprodukt-plastike-red-2-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cam Fiber"])},
  "poluprodukt-plastike-red-2-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 245, 400, 600"])},
  "poluprodukt-plastike-red-2-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabardin 2/2 Düz"])},
  "poluprodukt-plastike-red-3-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düz"])},
  "poluprodukt-plastike-red-4-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hibrid"])},
  "poluprodukt-plastike-red-4-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtiyaca göre"])},
  "poluprodukt-plastike-red-4-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İhtiyaca göre"])},
  "poluprodukt-plastike-red-5-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOA Prepreg"])},
  "poluprodukt-plastike-opis-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otoklav dışında (OOA) önceden emprenye (prepreg) malzemeler, işleme maliyetlerini azaltma ve AC tarafından uygulanan boyut kısıtlamaları olmadan yapılar inşa etme potansiyeli sunan geleneksel otoklav işleme (AC) için umut vaat eden bir alternatiftir. OOA prepregler, sadece vakum torbası ve fırın iyileştirmesi ile işleme için tasarlanmış olduklarından AC ile işlenmiş prepreglerden farklıdır. Dikkat: Soğutma gerektirir."])},
  "poluprodukt-plastike-tabela-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gram/Metrekare"])},
  "poluprodukt-plastike-kolona-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örgü"])},
  "poluprodukt-plastike-red-1-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karbon"])},
  "poluprodukt-plastike-red-1-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200, 380, 600"])},
  "poluprodukt-plastike-red-1-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabardin 2/2 Düz"])},
  "poluprodukt-plastike-red-2-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum Malzemeleri"])},
  "poluprodukt-plastike-opis-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolayca draplanabilir akış yardımı, çıkış filmi, delikli film ve vakum enfüzyonları veya vakum torbalama için hızlı ve güvenli bir hazırlık sağlayan yeni ve akıllı kombinasyon. Farklı sıcaklık aralıkları veya farklı yapışkan mukavemetler için çeşitli vakum sızdırmazlık bantları teklifi tamamlar."])},
  "poluprodukt-plastike-tabela-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış Filmleri"])},
  "poluprodukt-plastike-red-1-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeşitli kalınlıklar"])},
  "poluprodukt-plastike-red-1-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NP, P3, P33"])},
  "poluprodukt-plastike-red-2-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum Filmleri"])},
  "poluprodukt-plastike-red-2-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeşitli kalınlıklar"])},
  "poluprodukt-plastike-red-2-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150/300"])},
  "poluprodukt-plastike-red-3-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluklama/Kumaşlar"])},
  "poluprodukt-plastike-red-3-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeşitli kalınlıklar"])},
  "poluprodukt-plastike-red-3-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum Hortumları"])},
  "poluprodukt-plastike-red-4-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otoklav/vakum enfüzyon için"])},
  "poluprodukt-plastike-red-4-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyulabilir Kumaş"])},
  "poluprodukt-plastike-red-5-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçineler/Sertleştiriciler"])},
  "poluprodukt-plastike-opis-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UP reçinelerine kıyasla, epoksi reçineler önemli ölçüde iyileştirilmiş mekanik özellikler sunar (statik ve dinamik dayanıklılık, elastiklik modülü) ve kimyasal dirence sahiptir. Epoksi reçinelerin işlenmesi aynı zamanda çok daha basittir, zararlı stiren buharlarını ortadan kaldırır. Vakum infüzyonu, RTM veya el ile yapılan işler gibi farklı işlemler için geniş bir viskozite aralığı, üretimde esneklik sunar. Tüm sertleştiriciler birbirleriyle uyumludur ve özellikleri bireysel olarak ayarlamak için karıştırılabilir."])},
  "poluprodukt-plastike-tabela-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havacılık Onaylı"])},
  "poluprodukt-plastike-red-1-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakum Infüzyonu İçin"])},
  "poluprodukt-plastike-red-2-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapısal Bileşenler İçin"])},
  "poluprodukt-plastike-red-3-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metaller"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metal İşleme. Metaller kategorisinde müşterilerimize çeşitli işleme seçenekleri (taahhüt işleri) sunuyoruz. Aşağıdaki hizmetler taahhüt işi olarak sunulmaktadır. CNC Torna, CNC Freze, 3D Baskı, Taşlama, Parlatma, Delme, Bükme, Delme, Sac İşleme, Kaynak, Kaynak Konstrüksiyonları, Lazer Kazıma, Lazer Kesim ve Kalıp Yapımı."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Torna/Freze"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni zorlukları aşma konusunda esnekiz ancak kalite ve teslim tarihleri konusunda kesinlikle hassasız. Üretimimiz yalnızca en son teknoloji yüksek teknoloji makineler ve en son ölçüm cihazlarıyla donatılmıştır. Müşterilerimizle yakın işbirliği yaparak optimal süreçlere ulaşıyoruz. Yetkili kişiler sorularınıza hızlı cevap verir. Teknik plastikleri işleme konusunda mükemmel deneyime sahibiz ve sorularınızı bekliyoruz."])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak/Kaynak Konstrüksiyonları"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak konstrüksiyonları üretimi için çeşitli süreçler kullanıyoruz (MIG, MAG, TIG). Çelik, paslanmaz çelik veya alüminyum gibi malzemeleri isteğinize göre kullanıyoruz. Üretim, içeride tasarlanan veya sizin tarafınızdan sağlanan çizim şablonlarına dayanmaktadır."])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Mobilya & Tasarım"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoride yerel yeteneklerimizi tanıtıyor ve kendilerini ifade etmeleri için bir platform sunuyoruz. Büyük yaratıcılıkla, doğal kompozit malzemeler ve teknoloji kullanılarak bireysel başyapıtlar yaratılır. Her parça benzersizdir. Lütfen kendiniz göz atın."])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit Malzemeler/Doğa/Teknoloji"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit Malzemeler DÜKKAN"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel mobilya üretimi kategorisinden açıklama"])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetler"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bölümde, proje organizasyonu, uygulama teknik danışmanlık, montaj servisi, inşaat, ters mühendislik, prototipleme, prototip geliştirme ve üretim gibi ek hizmetler sunuyoruz."])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetler"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Organizasyonu"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje organizasyonunda, projelerinizi uygulama, kontrol ve kalite güvencesi ile, tarafınızdan istenen anlaşma/ilkeler ve uluslararası standartlara göre eşlik ediyoruz. Her yeni zorluğa sabırsızlıkla bekliyoruz."])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaj Servisi"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaj servisi için, önceden üretilmiş bileşenler bir fonksiyonel son ürüne (montaj) monte edilir. Bu, mekanik, elektronik, pnömatik ve/veya hidrolik sistemlerin montajını içerir. Bireysel bileşenlerin montajında, deneyimli montaj ekiplerimiz, bunları bir çizime göre bir bileşen montajı veya bir kısmi montaj haline getirir. Montaj, bileşenlerinize özel bir montaj hattında gerçekleşir, her bir montaj adımı profesyonel ve ekonomik bir şekilde gerçekleştirilir. Her montajdan sonra detaylı kalite kontrolleri yapılır ve gönderim için onay verilir."])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnşaat"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makinelerin fikirden tamamlanmasına kadar birçok adım gereklidir, ideal olarak tek bir üreticinin sorumlulukları dahilinde. Özel makine yapımı ve kalıp yapımı dahil olmak üzere mekanik ve tesis mühendisliği alanındaki çeşitli bileşenlerin inşası konusunda uzmanlaştık. Otomatik sistemlerden besleme teknolojisine kadar sac işleme – ihtiyacınız olan tüm adımları sizin için hallediyoruz ve sizinle yakın işbirliği yapıyoruz. Son teknoloji CAD programlarına dayalı (SolidWorks ve AutoDesk Inventor) özel makine yapımı ve inşaat alanındaki çekirdek yeteneklerimize ek olarak, ihtiyaçlarınız ve fikirleriniz için yenilikçi çözümler sunuyoruz. Proje boyunca sizin sürekli eşlikçiniziz ve ortak fikir alışverişinden memnuniyet duyarız."])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ters Mühendislik"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aşınmış bileşenlerin, mevcut CAD verilerinin olmamasının yanı sıra olmayan teknik çizimlerin ters mühendislik alanında, hasar görmüş veya aşınmış bileşenleri yeniden yapılandırma ve yeniden üretme konusunda yılların deneyimine sahibiz. Sorularınızı veya projelerinizi bekliyoruz."])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer Mobilya & Tasarım"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kategoride yerel yeteneklerimizi tanıtıyor ve kendilerini ifade etmeleri için bir platform sunuyoruz. Doğal kompozit malzemeler ve teknoloji kullanılarak büyük yaratıcılıkla bireysel başyapıtlar yaratılır. Cimmer Tasarımı eserlerini sunar ve sergiler."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit Malzemeler/Doğa/Teknoloji"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozit Malzemeler Dükkanı"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bireysel mobilya üretimi kategorisinden açıklama"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F.E.S-BH D.O.O şirketi, Bosna-Hersek'te merkez ofise sahip olup iki konuma ve Türkiye'de bir konuma sahiptir. Hemen hemen tüm endüstri alanlarında sistem tedarikçisiyiz, bu alanlar arasında makine ve tesis inşaatı, taşıyıcı teknoloji, otomotiv, yenilenebilir enerji, tıbbi teknoloji ve havacılık bulunmaktadır. Avrupa'daki zorlukları zamanında tanıyarak, mevcut teknik yeteneklerimiz ve deneyimimizle Avrupa bölgesine destek olma fırsatını kucakladık. Katkı marjlarınızı artırarak ve şu anda çok yüksek maliyetleri azaltarak işlerinizi güvence altına alabilir ve rekabet avantajı sağlayabilirsiniz. Ekonomik avantajlarımızı kendinizden emin bir şekilde kontrol edin ve ayrıca bilgi birikimimizden ve satış ve üretim ortaklarıyla geniş bir ağdan faydalanın. Her yeni zorluk ve projeye dört gözle bakıyoruz. Tereddüt etmeyin, bizimle iletişime geçin."])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])}
}