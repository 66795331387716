export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title on english"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Our Product site"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunststoffe"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertigteile"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktwender"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit einem unserer Murtfeldt Wender erreichen Sie ein Höchstmaß an Individualität und Flexibilität für die Auslegung der Produktionsanlage. Wir produzieren Wender nicht in Serie, sondern entwickeln und designen sie in enger Abstimmung mit Ihnen. Hier zum Produktportfolio "])},
  "produkt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.murtfeldt.de/de/produkte/individuelle-produkte/produktwender"])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnecken"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bieten ein breitgefächertes Fertigungsspektrum an individuellen Förderschnecken aus Kunststoff – ob Vereinzelungsschnecken, Gruppier-Schnecken, 1-in-2-Strangschnecken, 2-in-1-Strangschnecken, Dosier- und Förderschnecken, Wendeschnecken oder Wendegruppierschnecken. Sie alle transportieren Güter stetig, schnell und schonend. Hier zum Produktportfolio "])},
  "produkt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/foerderschnecken"])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatsatz"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Formatsätze bestehen aus Förderschnecken, Fördersternen und Führungsbauteilen, gefertigt aus Kunststoffen unseres umfangreichen Portfolios. Förderschnecken werden, wie auf der entsprechenden Seite beschrieben, exakt auf die jeweilige Flaschenform und -proportion abgestimmt. Die Fördersterne können komplett, zweiteilig oder für sehr große Anlagen als Segmente gefertigt werden. Führungsbauteile werden in projektspezifisch abgestimmter Form und Dimension auf Wunsch auch mit Wechselgleitleisten produziert. Unsere Kunststoffbearbeitung ist in allen Fertigungsprozessen optimiert. Hier zum Produktportfolio "])},
  "produkt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/individuelle-produkte/formatsaetze"])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spannbox"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der internationale Standard bei Ketten- und Riemenspannsystemen vom Marktführer 'designend & produced in Germany since 1965'. Unsere Spannsysteme halten Ketten und Riemen zuverlässig auf Spannung und fördern damit deren Lebensdauer. Einmal montiert, spannt der Spanner die Kette bzw. den Riemen vollautomatisch und ist somit die erste Wahl bei Spannsystemen mit Federkraftwirkung. Hier zum Produktportfolio "])},
  "produkt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/spannsysteme-fuer-ketten-und-riemen"])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo Reibung auftritt, kommt es zu Verschleiß. Vor allem da, wo Ketten auf Metallführungen laufen, ist ein regelmäßiges Schmieren erforderlich, um Abnutzung und Geräuschentwicklung entgegenzuwirken. Die selbstschmierenden Kettenführungen, Riemenführungen und Gleitprofile aus unseren bewährten Murtfeldt Kunststoffen 'S' und 'S1000' sind nicht nur eine preisgünstige Alternative zu Lösungen aus Metall. Als Gleitschiene für Rollenketten, Rundgliederketten oder Riemen kombinieren sie optimale Laufeigenschaften mit höchster Verschleißfestigkeit und garantieren einen geräuscharmen, leichten Lauf. Dies schont Ketten und Riemen und hilft Ihnen, Wartungsintervalle von Anlagen und Maschinen zu minimieren. Zum Portfolio "])},
  "produkt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/produkte/profile-fuehrungen-und-gleitschienen"])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composites/Halbzeuge"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkstoff S Gruppe"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik der Werkstoffe PE-UHMW, PE-HMW. Seit den 50er Jahren steht Werkstoff S für ein breites Anwendungsspektrum in der Antriebs- und Fördertechnik bis tausendfach bewährt. Werkstoff S wird auf Basis eines reinen ultrahochmolekularen Niederdruckpolyethylens (PE-UHMW TG 1.2) produziert und übertrifft die Anforderungen der DIN 16972 deutlich. Original Werkstoff S gehört zu der erfolgreichsten Materialgruppe unter den industriellen Kunststoffen. Insbesondere Original Werkstoff S grün hat sich als Marke im Kunststoffbereich etabliert und wird überall dort eingesetzt, wo technisch leistungsfähiger Kunststoff benötigt wird. Original Werkstoff S grün steht als Synonym für gute Gleiteigenschaften, hohe Verschleißfestigkeit und lange Lebensdauer. Hier geht’s zum Werkstoff S Portfolio "])},
  "poluprodukt-plastike-link-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Werkstoff-S-Gruppe/"])},
  "poluprodukt-plastike-tabela-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische Kunststoffe"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik der Werkstoffe PA6, PA66, PA12, POM-C, PET. In dem Beispiel des modifizierten Gusspolyamids, produziert auf Basis von PA6 C, wird während der Polymerisation ein mineralisches Cöl eingebunden. Dadurch wirkt das Material selbstschmierend und behält seine hervorragenden Eigenschaften über die gesamte Lebensdauer. Dies senkt Ihre Betriebs- und Wartungskosten deutlich. Kaum ein Kunststoff unseres Sortiments verfügt über bessere Gleiteigenschaften. Zudem verfügt Murlubric über ausgezeichnete Verschleiß- und eignet sich auch bei höheren Geschwindigkeiten für stark beanspruchte Gleit- und Verschleißanwendungen. Murlubric blau 'FS' ist im Gegensatz zum schwarzen Murlubric lebensmittelrechtlich (EU und FDA) zugelassen. Hier geht’s zum Portfolio unserer technischen Werkstoffe  "])},
  "poluprodukt-plastike-link-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Technische-Kunststoffe/"])},
  "poluprodukt-plastike-tabela-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochleistungskunststoffe"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik der Werkstoffe PA46, PVDF, PTFE, PPS, PEEK. Überall dort, wo überragende Widerstandsfähigkeit gegen chemische und Heißdampfbelastung gefordert wird, sind Murflor Werkstoffe geeignet. So beginnt der Arbeitsbereich der Murflor Werkstoffe bei -200°C und kann ohne mechanische Belastung bis zu +260°C betragen. Das auf Basis von PTFE produzierte Murflor verfügt darüber hinaus über den geringsten Gleitreibungskoeffizienten aller Thermoplaste. Hier zum Portfolio der Hochleistungswerkstoffe"])},
  "poluprodukt-plastike-link-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murtfeldt.de/de/shop/category/Kunststoffe/Hochleistungskunststoffe/"])},
  "poluprodukt-plastike-tabela-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium PE Kunststoffe"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrik der Werkstoffe PE-1000, PE-500. Premium-Kunststoffe von Murdotec bieten mehr als ein standardisiertes PE-UHMW. Sie zeichnen sich durch eine sehr gute Chemikalienbeständigkeit, eine sehr hohe Schlagzähigkeit und ein geringes Gewicht aus. Sie suchen einen vielseitigen technischen Kunststoff? Wir bieten Ihnen hier die passende Lösung. Zum Portfolio "])},
  "poluprodukt-plastike-link-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-premium-pe-kunststoffe.html"])},
  "poluprodukt-plastike-tabela-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezialkunststoffe PE-UHMW"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die enge Zusammenarbeit mit Kunden und intensiver Forschungsarbeit in unseren Laboren hat Murdotec seine PE-Kunststoffe immer wieder weiterentwickelt. Das versetzt uns heute in die Lage, individuell und gezielt auf Kundenwünsche zu reagieren. Ob verschleißoptimiert, gleitoptimiert für POM oder Metalle, wärmestabilisiert, antimikrobiell, metalldetektierbar, antistatisch oder elektrisch leitfähig: Wir bieten die Eigenschaften, die Sie für Ihre Einsatzzwecke benötigen. Zum Portfolio "])},
  "poluprodukt-plastike-link-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-spezialkunststoffe.html"])},
  "poluprodukt-plastike-tabela-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umlaufkunststoffe"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisches PE ist aufgrund seines molekularen Aufbaus für die Wiederverwendung von Spänen und Stückgut hervorragend geeignet. Dabei beeinflussen Sortenreinheit und der hohe Mahlgrad die Qualität enorm. Deshalb lagern wir unsere zur Wiederverwendung geeigneten Materialien sortenrein und mischen nur Typen gleicher Güte. Damit nicht genug: In unseren Mischlinien wird jeder Charge eine genau bestimmte Menge an Neuware hinzugefügt. Sie erhalten mit Murdotec 1000 U ein aufbereitetes Material aus dem Grundwerkstoff PE-UHMW in höchster Qualität."])},
  "poluprodukt-plastike-link-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/murdotec-umlaufkunststoffe.html"])},
  "poluprodukt-plastike-tabela-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialen"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt viele unterschiedliche Arten von Schüttgut. Zucker, Salz und Getreide zählen ebenso dazu wie Sand, Kies oder Kohle. Das verbindende Element zwischen diesen unterschiedlichen Rohstoffen ist die Lagerung in Silos und auch die Frage nach dem geeigneten Auskleidungswerkstoff für die Silos verbindet diese Schüttgüter. Hier zur Einsatzmöglichkeit "])},
  "poluprodukt-plastike-link-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://murdotec.de/dialen.html"])},
  "poluprodukt-plastike-tabela-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "poluprodukt-plastike-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trockengewebe/Gewebe"])},
  "poluprodukt-plastike-opis-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Lieferprogramm umfasst hochwertigste Gewebe aus Kohlestofffasern, Glasfasern und Aramidfasern. Die Gewebe unterscheiden sich in den Bindungsarten, welche anwendungsspezifisch ausgewählt werden. Die wichtigsten Bindungsarten sind: Leinwandbindung, Köperbindung, Satinbindung. Ein weiteres Auswahlkriterium ist das Flächengewicht in g/m2. Wir halten für Sie Gewebe von ca. 25 g/m2 bis 1.000 g/m2 bereit."])},
  "poluprodukt-plastike-link-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-tabela-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramm/Qaudrameter"])},
  "poluprodukt-plastike-kolona-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindung"])},
  "poluprodukt-plastike-red-1-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200,245,400,600"])},
  "poluprodukt-plastike-red-1-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Leinwand"])},
  "poluprodukt-plastike-red-2-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glasfaser"])},
  "poluprodukt-plastike-red-2-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["98,200,245,400,600"])},
  "poluprodukt-plastike-red-2-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Leinwand"])},
  "poluprodukt-plastike-red-3-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leinwand"])},
  "poluprodukt-plastike-red-4-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepreg"])},
  "poluprodukt-plastike-opis-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immer wenn es auf maximale Performance im Composite-Bereich ankommt, werden Prepregs (Preimpregnated)             eingesetzt. Konstant niedriger Faservolumenanteil und eine perfekte Tränkung der Faser ermöglichen die Fertigung leistungsfähiger Faserverbundbauteile. Für die kontrollierte, porenfreie Aushärtung des Bauteils werden Autoklaven eingesetzt. Gerne gehen wir auf Ihre individuellen Anforderungen ein und liefern kurzfristig die gewünschten Fasern und das benötigte Einsatzprofil. Achtung Kühltruhe/Kühlraumbenötigt"])},
  "poluprodukt-plastike-link-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-tabela-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramm/Qaudrameter"])},
  "poluprodukt-plastike-kolona-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindung"])},
  "poluprodukt-plastike-red-1-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200,245,400,600"])},
  "poluprodukt-plastike-red-1-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Leinwand"])},
  "poluprodukt-plastike-red-2-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glasfaser"])},
  "poluprodukt-plastike-red-2-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200,245,400,600"])},
  "poluprodukt-plastike-red-2-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Leinwand"])},
  "poluprodukt-plastike-red-3-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramid"])},
  "poluprodukt-plastike-red-3-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["175"])},
  "poluprodukt-plastike-red-3-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leinwand"])},
  "poluprodukt-plastike-red-4-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
  "poluprodukt-plastike-red-4-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Bedarf"])},
  "poluprodukt-plastike-red-4-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Bedarf"])},
  "poluprodukt-plastike-red-5-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOA Prepreg"])},
  "poluprodukt-plastike-opis-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out-of-Autoclave (OOA) vorimprägnierte (Prepreg) Materialien sind eine vielversprechende Alternative zur herkömmlichen Autoklavenverarbeitung (AC), mit dem Potenzial, die Verarbeitungskosten zu senken und Strukturen ohne Größenbeschränkungen durch den AC zu bauen. OOA-Prepregs unterscheiden sich von AC-verarbeiteten Prepregs dadurch, dass sie nur für die Verarbeitung mit einem Vakuumbeutel und einer Ofenhärtung ausgelegt sind. Achtung Kühltruhe/Kühlraumbenötigt"])},
  "poluprodukt-plastike-link-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-tabela-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gramm/Qaudrameter"])},
  "poluprodukt-plastike-kolona-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bindung"])},
  "poluprodukt-plastike-red-1-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbon"])},
  "poluprodukt-plastike-red-1-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["200,380,600"])},
  "poluprodukt-plastike-red-1-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twill 2/2 Leinwand"])},
  "poluprodukt-plastike-red-2-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuummaterial"])},
  "poluprodukt-plastike-opis-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leicht drapierbare Fließhilfe, die neue und clevere Kombination von Saugvlies, Lochfolie und Abreißgewebe oder Sprühkleber mit gleichmäßigem Sprühbild sorgen für schnelle und sichere Vorbereitung von Vakuuminfusionen bzw. Vakuum-Bagging. Verschiedene Vakuumdichtbänder für unterschiedliche Temperaturbereiche oder mit unterschiedlicher Klebkraft runden das Angebot ab."])},
  "poluprodukt-plastike-link-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-tabela-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennfolien"])},
  "poluprodukt-plastike-red-1-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verschiedene Dicken"])},
  "poluprodukt-plastike-red-1-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NP, P3, P33"])},
  "poluprodukt-plastike-red-2-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuumfolien"])},
  "poluprodukt-plastike-red-2-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verschiedene Dicken"])},
  "poluprodukt-plastike-red-2-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["150/300"])},
  "poluprodukt-plastike-red-3-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breather/Flies"])},
  "poluprodukt-plastike-red-3-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verschiedene Dicken"])},
  "poluprodukt-plastike-red-3-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakuumschläuche"])},
  "poluprodukt-plastike-red-4-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für Autoklav/Vakuuminfusion"])},
  "poluprodukt-plastike-red-4-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreißgewebe"])},
  "poluprodukt-plastike-red-5-2-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epoxitharze/Härter"])},
  "poluprodukt-plastike-opis-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Vergleich zu den UP-Harzen bieten Epoxidharze deutlich verbesserte Eigenschaften hinsichtlich mechanischer Kennwerte (statische und dynamische Festigkeit, E-Modul) und chemischer Beständigkeit. Auch ist die Verarbeitung von Epoxidharzen deutlich einfacher, schädliche Styroldämpfe entfallen. Ein breites Spektrum an Viskositäten für die unterschiedlichen Verfahren wie Vakuuminfusion, RTM oder Handlaminieren bietet Flexibilität in der Fertigung. Alle Härter sind untereinander kompatibel und können zur individuellen Einstellung der Eigenschaften gemischt werden."])},
  "poluprodukt-plastike-link-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-tabela-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])},
  "poluprodukt-plastike-kolona-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-kolona-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit Luftfahrzulassung"])},
  "poluprodukt-plastike-red-1-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-1-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für Vakuuminfusion"])},
  "poluprodukt-plastike-red-2-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-2-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für Strukturbauteile"])},
  "poluprodukt-plastike-red-3-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-3-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-4-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-1-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-2-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "poluprodukt-plastike-red-5-3-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metalle"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metallbearbeitung/Verarbeitung. In der Rubrik Metalle bieten wir unseren Kunden verschiedene Bearbeitungs- und Verarbeitungsmöglichkeiten (Lohnarbeit) an.Folgende bieten wir unseren Kunden in Lohnarbeit an. -CNC Drehen, CNC Fräsen,3D-Druck, Schleifen, Polieren, Bohren, Biegen, Stanzen, Blechbearbeitung, Schweißen, Schweißkonstruktionen, Lasergravieren, Laserschneiden sowie Formenbau."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Drehen/Fräsen"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind flexibel, wenn es um das Meistern neuer Herausforderungen geht, aber absolut präzise bei Qualität und Liefertermin. Unsere Produktion ist ausschließlich mit modernsten Hightech-Maschinen und neuesten Messmitteln ausgestattet. Durch enge Zusammenarbeit mit unseren Kunden erzielen wir optimale Prozesse. Kompetente Ansprechpartner reagieren schnell auf Ihre Anfragen. Wir bauen auf eine ehrliche und langfristige Partnerschaft mit unseren Kunden. Hier verfügen wir über exellente Erfahrungen in der Zerspanung von technischen Kunststoffen, wir freien uns auf Ihre Anfragen."])},
  "produkt-metali-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC Fräsen ovu rubriku izbrisi, sastavio sam Drehen/fräsen"])},
  "produkt-metali-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!!!!!!!!!!!!!!! storno"])},
  "produkt-metali-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweißen ovu Rubriku izbrisi satavicemo sa Schweisen/Schweiskonstuktionen"])},
  "produkt-metali-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!!!!!!!!!!!!!!! storno"])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweißen/Schweißkonstruktionen"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Produktion von Schweißkonstruktion greifen wir auf unterschiedliche Verfahren (MIG, MAG, WIG) zurück.   Wir verwenden Werkstoffe, wie z.B. Stahl, Edelstahl oder Aluminium ganz nach Ihren Wünschen. Die Fertigung erfolgt nach Zeichnungsvorlagen, die entweder bei uns im Haus konstruiert oder von Ihnen als Vorgabe mitgebracht wurden."])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Rubrik fördern wir unsere einheimischen Talente und bieten Ihnen eine Plattform sich zu entfalten. Mit sehr großer Kreativität entstehen individuelle Meisterwerke aus Natur/Verbundstoffen/Technik. Jedes Teil ist ein UNIKAT. Bitte überzeugen Sie sich selbst "])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundwerkstoffe/Natur/Technik"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbundstoffe SHOP"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung aus der Kategorie der individuellen Möbelherstellung"])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Rubrik bieten wir weitere Dienstleistungen wie Projektorganisation, Anwendungstechnische Beratung, Montageservice für Baugruppen, Konstruktion, Reverse Engineering, Prototyping, Prototypenentwicklung und Herstellung"])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektorganisation"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Projektorganisation begleiten wir Ihre Projekte in der Umsetzung, Steuerung und Qualitätssicherung mit den von Ihnen angeforderten Absprachen/Vorgaben und nach internationalen Normen. Wir freuen uns auf jede neue Herausforderung. "])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugruppen Montageservice"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Baugruppenmontageservice werden vorgefertigte Bauteile und Komponenten zu einem funktionsfähigen Endprodukt (Baugruppe) zusammengesetzt. Das umfasst den Zusammenbau von mechanischen, elektronischen, pneumatischen und/oder hydraulischen Systemen. Bei der Montage der Einzelkomponenten setzten unsere erfahrenen Montageteams, diese nach dem Bauplan zu einer Komponenten Baugruppe oder einer Teilbaugruppe fachgerecht zusammen. Die Baugruppenmontage erfolgt in einer auf Ihre Komponenten optimal zugeschnittenen Montagelinie, jeder einzelne Montageschritt wird professionell und zugleich sehr wirtschaftlich ausgeführt. Nach jeder Montage erfolgen ausführliche Qualitätskontrollen und die Freigabe zum Versand. "])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruktion"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Von der Idee bis zur Fertigstellung von Maschinen, bedarf es vieler Schritte, die idealerweise im Aufgabenbereich eines einzigen Herstellers liegen sollten. Wir sind spezialisiert auf die Konstruktion unterschiedlichster Bauteile im Maschinen- und Anlagenbau sowie im Formbau. Von automatisierten Anlagen über Zuführtechnik bis hin zur Blechbearbeitung – wir übernehmen für Sie alle notwendigen Schritte und arbeiten eng mit Ihnen zusammen. Neben unserer Kernkompetenz im Sondermaschinenbau und Konstruktion auf der Basis aktuellster CAD-Programme (SolidWorks und AutoDesk Inventor) bieten wir innovative Lösungen für Ihre Wünsche und Ideen. Während des gesamten Projektes sind wir Ihr stetiger Begleiter und freuen uns auf einen gemeinsamen Ideenaustausch. "])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse Engineering"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Bereich des Reverse Engineering von verschlissenen Bauteilen, nicht vorhandenen CAD-Daten sowie nicht vorhandenen technischen Zeichnungen, haben wir hier jahrelange Erfahrungen, anhand beschädigter oder verschlissener Bauteile diese nachzukonstruieren und diese wieder Rezuproduzieren bzw. herstellen zu können. Wir freuen uns auf Ihre Anfragen oder Projekte. "])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Kategorie fördern wir unsere lokalen Talente und bieten ihnen eine Plattform, um sich auszudrücken. Mit großer Kreativität entstehen individuelle Meisterwerke aus natürlichen Verbundwerkstoffen und Technologie. Cimmer Design präsentiert und zeigt seine Werke."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trgovina kompozitnim materijalima"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije proizvodnje individualnog namještaja"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Firma F.E.S-BH D.O.O, ist mit Hauptsitz in Bosnien und Herzegowina mit zwei Standorten, sowie der Türkei an einem Standort vertreten. Wir sind Systemlieferant in fast alle Bereiche der Industrie wie Maschinen- und Anlagenbau, Fördertechnik, Automotive, erneuerbare Energien, Medizintechnik sowie Luft- und Raumfahrt. Rechtzeitig haben wir die Problematik in Europa erkannt und die Herausforderungen angenommen, den Europäischen Raum mit unseren vorhandenen, technischen Möglichkeiten und Erfahrungen zu unterstützen. Mit Erhöhung ihrer Deckungsbeiträge und Senkung der mittlerweile sehr hohen Kosten, sichern Sie Ihre Arbeitsplätze und bleiben weiterhin Wettbewerbsfähig. Überzeugen Se sich selbst von den wirtschaftlichen Vorteilen und profitieren auch Sie, von unserem Know How sowie einem grossem Netzwerk an Vertriebs und Produktionpartnern. Wir freuen uns sehr auf jede neue Herausforderung und jedes neue Projekt. Zögern Sie nicht, kontaktieren Sie uns"])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
}