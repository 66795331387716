<template>
    <div>
      <h2>Usluge</h2>
      
      <!-- Dodavanje novog reda -->
      <button @click="addNewRow">Dodaj novi red</button>
      
      <!-- Tabela sa podacima -->
      <table>
        <thead>
          <tr>
            <th>Produkt</th>
            <th>Opis</th>
            <th>Akcije</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(produkt, index) in plastika" :key="index">
          <td>{{ produkt.produkt }}</td>
          <td>{{ produkt.produktOpis }}</td>
        </tr>
      </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        plastika: [],
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
      // Učitajte JSON fajl
      axios.get('/fajlovi/bs.json')
        .then(response => {
          // Filtrirajte podatke za ključeve koji sadrže "produkt-plastike"
          const plastikaData = {};
          for (const key in response.data) {
            if (key.includes('produkt-plastike')) {
              // Ovde možete izvršiti dalju obradu ključeva
              const produktKey = key.split('-')[1]; // Pretpostavlja se da je "produkt-plastike-1", "produkt-plastike-2", itd.
              plastikaData[produktKey] = response.data[key];
            }
          }

          // Konvertujte filtrirane podatke u niz objekata
          this.plastika = Object.keys(plastikaData).map(key => ({
            produkt: key,
            produktOpis: plastikaData[key],
          }));
        })
        .catch(error => {
          console.error('Greška prilikom učitavanja podataka:', error);
        });
    },
      updateRow(produkt) {
        axios.put('bs.json', produkt)
          .then(response => {
            console.log('Podaci su uspešno ažurirani:', response);
          })
          .catch(error => {
            console.error('Greška prilikom ažuriranja podataka:', error);
          });
      },
      deleteRow(index) {
        if (confirm('Da li ste sigurni da želite da obrišete ovaj red?')) {
          axios.delete('bs.json', { data: this.usluge[index] })
            .then(response => {
              console.log('Podaci su uspešno obrisani:', response);
              this.usluge.splice(index, 1); // Uklanjanje reda iz tabele
            })
            .catch(error => {
              console.error('Greška prilikom brisanja podataka:', error);
            });
        }
      },
      addNewRow() {
        const newRow = {
          naziv: '',
          opis: '',
        };
        axios.post('bs.json', newRow)
          .then(response => {
            console.log('Novi red je uspešno dodat:', response);
            this.usluge.push(newRow); // Dodavanje novog reda u tabelu
          })
          .catch(error => {
            console.error('Greška prilikom dodavanja novog reda:', error);
          });
      },
    },
  };
  </script>
  