<template>
    

    <div id="app">
    <v-row>
        <v-col
        v-for="(src, index) in imgs"
        :key="index"
        
        @click="() => showImg(index)"
        class="d-flex child-flex"
        cols="4"
        >
        <v-img cover  class="bg-grey-lighten-2" :src="src" >
            <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                    indeterminate
                    color="grey-lighten-5"
                    ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
        </v-col>
    </v-row>


  <vue-easy-lightbox
    :visible="visible"
    :imgs="imgs"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</div>

</template>
<script>

// If VueApp is already registered with VueEasyLightbox, there is no need to register it here.
import VueEasyLightbox from 'vue-easy-lightbox'


export default ({
  components: {
    VueEasyLightbox
  },

  data() {
    return {
        visible: false,
        index: 0, // default: 0
        imgs: [
            'https://picsum.photos/500/300?image=160',
            'https://picsum.photos/500/300?image=80',
            'https://picsum.photos/500/300?image=20',
            'https://picsum.photos/500/300?image=35',
            { src: 'https://picsum.photos/500/300?image=45', title: 'Naslov slike' }
        ]

    };
  },

methods:{
    showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
}

})
</script>